import { camelCaseResponse } from "../shared/Functions";
import { emptyApi } from "./emptyApi";

const addHyphensToMovementTypes = (movementTypes: string[]) => {
  return movementTypes.map((type: string) => {
    if (type.includes(" ")) {
      return type.replace(" ", "-");
    } else {
      return type;
    }
  });
};

export const reportExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportsByOrgPlayerId: builder.query({
      query: (args) => {
        const { limit, orgPlayerId, offset, movementTypes } = args;
        const formattedMovementTypes = addHyphensToMovementTypes(movementTypes);
        return {
          url: `/reports`,
          params: {
            limit,
            offset,
            org_player_ids: orgPlayerId,
            movement_types: formattedMovementTypes,
          },
        };
      },
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    getReportsBySessionId: builder.query({
      query: (args) => {
        const { sessionId, movementTypes } = args;
        const formattedMovementTypes = addHyphensToMovementTypes(movementTypes);
        return {
          url: `/reports`,
          params: {
            session_ids: sessionId,
            movement_types: formattedMovementTypes,
          },
        };
      },
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
  }),
});

export const {
  useGetReportsByOrgPlayerIdQuery,
  useGetReportsBySessionIdQuery,
} = reportExtendedApi;
