import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { PlayerGroups, Roster } from "..";
import { TabContent, TabItem } from "../../components";
import { useAppContext } from "../../context";
import { useAppSelector } from "../../redux/hooks";
import { playerListLimit } from "../../shared/Constants";
import { Player } from "../../types";
import { TabRouteParams } from "../../types/sharedTypes";

export type PlayersTabProps = {
  offsetForPlayerList: number;
  setOffsetForPlayerList: React.Dispatch<React.SetStateAction<number>>;
  playerQueryCount: number;
  setPlayerQueryCount: React.Dispatch<React.SetStateAction<number>>;
  combinedPlayers: Player[];
  setCombinedPlayers: React.Dispatch<React.SetStateAction<Player[]>>;
  numberOfQueriesForPlayers: number;
};

export const Players: React.FC = (): JSX.Element => {
  // State and AppContext
  const { setDisplayBack } = useAppContext();
  const [offsetForPlayerList, setOffsetForPlayerList] = useState(0);
  const [combinedPlayers, setCombinedPlayers] = useState<Player[]>([]);
  const [playerQueryCount, setPlayerQueryCount] = useState(0);

  // Redux calls
  const playerCount = useAppSelector((state) => state.org.players.total);

  const numberOfQueriesForPlayers = Math.ceil(playerCount / playerListLimit);

  const defaultActiveTab = "players";
  const { activeTab } = useParams<TabRouteParams>();
  const history = useHistory();

  useEffect(() => {
    if (!activeTab) {
      history.push(`/${defaultActiveTab}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      if (tab === "players") {
        history.push(`/${tab}`);
      } else {
        history.push(`/players/${tab}`);
      }
    }
  };

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
  }, [setDisplayBack]);

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      <div className="mt-8">
        <div className="max-w-8xl mx-auto w-full flex justify-between items-center pb-2">
          <div className="w-full flex flex-col justify-start px-4 sm:px-6 lg:px-8 lg:pb-6">
            <h2
              className="max-w-7xl mt-4 mb-8 text-3xl leading-6 font-bold text-gray-900"
              data-testid="players-header"
            >
              Players
            </h2>

            <div
              className="w-full border-b border-gray-200"
              data-testid="players-tabs-container"
            >
              <ul
                className="-mb-px flex space-x-8"
                data-testid="players-page-tabs"
              >
                <TabItem
                  page={"players"}
                  title={"All Players"}
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
                <TabItem
                  page={"player-groups"}
                  title={"Player Groups"}
                  activeTab={activeTab}
                  toggleTab={toggleTab}
                />
              </ul>
            </div>
          </div>
        </div>

        <div data-testid="players-page-contents">
          <TabContent
            page={"players"}
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <Roster
              offsetForPlayerList={offsetForPlayerList}
              setOffsetForPlayerList={setOffsetForPlayerList}
              playerQueryCount={playerQueryCount}
              setPlayerQueryCount={setPlayerQueryCount}
              combinedPlayers={combinedPlayers}
              setCombinedPlayers={setCombinedPlayers}
              numberOfQueriesForPlayers={numberOfQueriesForPlayers}
            />
          </TabContent>
          <TabContent page={"player-groups"} activeTab={activeTab}>
            <PlayerGroups
              offsetForPlayerList={offsetForPlayerList}
              setOffsetForPlayerList={setOffsetForPlayerList}
              playerQueryCount={playerQueryCount}
              setPlayerQueryCount={setPlayerQueryCount}
              combinedPlayers={combinedPlayers}
              setCombinedPlayers={setCombinedPlayers}
              numberOfQueriesForPlayers={numberOfQueriesForPlayers}
            />
          </TabContent>
        </div>
      </div>
    </main>
  );
};
