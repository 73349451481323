import { ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";
import { toast } from "react-hot-toast";

import { ErrorToast } from "../../../components";
import { uploadSessionSlice } from "../../../redux/slices/uploadSessionSlice";
import { UploadSession } from "../../../types";
import {
  FilePartInfo,
  PatchSessionUploadInfo,
  PreSignedUrl,
  S3Info,
} from "../../../types/apiTypes";
import {
  AbortControllerInfo,
  UploadFile,
  UploadFileInfo,
} from "../../../types/uploadSessionTypes";

// RTK slice actions
const { updateUploadSessionState, updateUploadProgress } =
  uploadSessionSlice.actions;

// Error Toast
const errorToast = (message: string) =>
  toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
    id: "s3UploadError",
    duration: 4000,
  });

// File chunk size
export const fileChunkSize = 1024 * 1024 * 5; // 5MB

// Uploads a single file to S3
export const uploadFileToS3Bucket = async (
  preSignedUrlInfo: PreSignedUrl | any,
  uploadSessionId: number,
  uploadFiles: UploadFile[],
  dispatch: ThunkDispatch<any, any, any>,
  patchSessionUpload: ({}: PatchSessionUploadInfo) => any,
  abortControllerInfo: AbortControllerInfo,
): Promise<void> => {
  const uploadedFile = uploadFiles.find((file: { name: any }) => {
    return file.name === preSignedUrlInfo?.fileName;
  });
  const fileChunks: Blob[] = [];

  if (uploadedFile && fileChunkSize) {
    for (let start = 0; start < uploadedFile.size; start += fileChunkSize + 1) {
      const chunk = uploadedFile.slice(start, start + fileChunkSize + 1);
      fileChunks.push(chunk);
    }
  }

  const signal = abortControllerInfo?.abortController.signal;

  const promises: Promise<Response | void>[] = [];

  preSignedUrlInfo?.info.forEach((info: S3Info) => {
    const url = new URL(info.url);
    const partNumber = Number(url.searchParams.get("partNumber"));
    promises.push(
      fetch(info.url, {
        method: "PUT",
        body: fileChunks[partNumber - 1],
        keepalive: false,
        signal,
      }).catch((error) => {
        console.warn(error);
      }),
    );
  });

  let progress = 0;

  promises.forEach((promise: Promise<Response | void>) =>
    promise.then((response) => {
      if (response?.status === 200) {
        const fileName = uploadedFile?.name;
        progress++;
        dispatch(
          updateUploadProgress({
            fileName,
            uploadProgress: Math.round((progress / promises.length) * 100),
            uploadSessionId,
          }),
        );
      }
    }),
  );

  const resParts: any = await Promise.all(promises);

  if (resParts.every((response: Response) => typeof response === "undefined")) {
    patchFilesToApi(
      preSignedUrlInfo,
      uploadSessionId,
      false,
      dispatch,
      patchSessionUpload,
    );
  } else if (
    resParts.every(
      (response: Response) =>
        typeof response !== "undefined" && response.status === 200,
    )
  ) {
    patchFilesToApi(
      preSignedUrlInfo,
      uploadSessionId,
      true,
      dispatch,
      patchSessionUpload,
      resParts,
    );
  } else {
    patchFilesToApi(
      preSignedUrlInfo,
      uploadSessionId,
      false,
      dispatch,
      patchSessionUpload,
    );
  }
};

// Final PATCH request sent to the api after S3 upload completes
const patchFilesToApi = (
  preSignedUrlInfo: any,
  uploadSessionId: number,
  success: boolean,
  dispatch: ThunkDispatch<any, any, any>,
  patchSessionUpload: ({}: PatchSessionUploadInfo) => any,
  resParts?: Response[] | undefined,
) => {
  const filePartInfo: FilePartInfo[] = preSignedUrlInfo.info.map(
    (info: any, index: number) => {
      let etag;
      if (resParts) {
        etag = resParts[index].headers.get("etag");
      }
      return {
        etag: etag,
        part_number: index + 1,
      };
    },
  );

  patchSessionUpload({
    uploadSessionId: uploadSessionId,
    fileId: preSignedUrlInfo.sessionFileId,
    filePartInfo: success ? filePartInfo : null,
    uploadSuccessful: success ? true : false,
  })
    .unwrap()
    .then(() => {
      if (success) {
        dispatch(
          updateUploadSessionState({
            fileName: preSignedUrlInfo.fileName,
            uploadSessionId,
          }),
        );
      } else {
        dispatch(
          updateUploadSessionState({
            fileName: preSignedUrlInfo.fileName,
            error: "Upload Failed",
            uploadSessionId,
          }),
        );
      }
    })
    .catch(() => {
      errorToast(
        `There was an issue uploading ${preSignedUrlInfo.fileName}. Please try again.`,
      );
      dispatch(
        updateUploadSessionState({
          fileName: preSignedUrlInfo.fileName,
          error: "Upload Failed",
          uploadSessionId,
        }),
      );
    });
};

// Retries S3 upload of a file
export const triggerNewS3Upload = (
  fileNameToRetry: string,
  uploadSessionState: UploadSession,
  uploadFiles: UploadFile[],
  dispatch: ThunkDispatch<any, any, any>,
  patchSessionUpload: ({}: PatchSessionUploadInfo) => any,
  abortControllerInfo: AbortControllerInfo,
): void => {
  const preSignedUrlInfoToRetry = uploadSessionState.preSignedUrls.find(
    (urlInfo) => urlInfo.fileName === fileNameToRetry,
  );
  const uploadSessionId = uploadSessionState.uploadSessionId;

  uploadFileToS3Bucket(
    preSignedUrlInfoToRetry,
    uploadSessionId,
    uploadFiles,
    dispatch,
    patchSessionUpload,
    abortControllerInfo,
  );
  dispatch(
    updateUploadSessionState({
      fileName: fileNameToRetry,
      error: "Retry Upload",
      uploadSessionId,
    }),
  );
};

// Cancels upload for a single file and aborts all associated fetch requests to S3
export const cancelFileUpload = (
  canceledFile: UploadFileInfo,
  dispatch: ThunkDispatch<any, any, any>,
  controller: AbortController,
  patchSessionUpload: ({}: PatchSessionUploadInfo) => any,
): void => {
  controller.abort();
  patchSessionUpload({
    uploadSessionId: canceledFile.uploadSessionId,
    fileId: canceledFile.sessionFileId,
    filePartInfo: null,
    uploadSuccessful: false,
  });
  dispatch(
    updateUploadSessionState({
      fileName: canceledFile.fileName,
      error: "Upload Canceled",
      uploadSessionId: canceledFile.uploadSessionId,
    }),
  );
};
