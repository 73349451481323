import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { ReactComponent as StepCircleComplete } from "../../assets/StepperAssets/StepCircleComplete.svg";
import { ReactComponent as XIcon } from "../../assets/xIcon.svg";
import { GradientProgressSpinner } from "../../components";
import { ModalHeader } from "../../components/ModalHeader/ModalHeader";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  DataExportFile,
  dataExportPopupSlice,
} from "../../redux/slices/dataExportPopupSlice";
import { useExportDataMutation } from "../../services/dataExport";
import { PopupProps } from "../../types/sharedTypes";

export const DataExportPopup: React.FC<PopupProps> = ({
  title,
}: PopupProps) => {
  const [minimizePopup, setMinimizePopup] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const {
    exportPopupState,
    setExportPopupState,
    exportInProgress,
    setExportInProgress,
  } = useAppContext();

  // Redux calls
  const dataExportFiles = useAppSelector(
    (state) => state.dataExportPopup.files,
  );

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { updateDataExportFileStatus, clearDataExportFiles } =
    dataExportPopupSlice.actions;

  // RTK Mutation
  const [exportData] = useExportDataMutation();

  const toggleMinimize = () => {
    setMinimizePopup(!minimizePopup);
    setShowContent(!showContent);
  };

  const makeApiRequests = () => {
    setExportInProgress(true);
    dataExportFiles.forEach((file) => {
      if (!file.status) {
        const body = {
          org_player_id: file.orgPlayerId,
          session_id: file.sessionId,
          movement_type_id: Number(file.movementType.id),
          data_type: file.dataType.id,
          data_format: file.dataFormat,
          aggregate: true,
        };

        exportData(body)
          .unwrap()
          .then((response) => {
            dispatch(
              updateDataExportFileStatus({
                sessionId: response.sessionId,
                dataType: response.dataType,
                movementType: response.movementTypeId.toString(),
                status: "success",
                downloadUrl: response.downloadUrls[0],
              }),
            );
            if (response.downloadUrls[0] !== "") {
              window.location.assign(response.downloadUrls[0]);
            }
          })
          .catch(() => {
            dispatch(
              updateDataExportFileStatus({
                sessionId: file.sessionId,
                dataType: file.dataType.id,
                movementType: file.movementType.id,
                status: "error",
              }),
            );
          });
      } else {
        return;
      }
    });
  };

  const retryExport = (file: DataExportFile) => {
    setExportInProgress(true);
    dispatch(
      updateDataExportFileStatus({
        sessionId: file.sessionId,
        dataType: file.dataType.id,
        movementType: file.movementType.id,
        status: "preparing",
      }),
    );

    const body = {
      org_player_id: file.orgPlayerId,
      session_id: file.sessionId,
      movement_type_id: Number(file.movementType.id),
      data_type: file.dataType.id,
      data_format: file.dataFormat,
      aggregate: true,
    };

    exportData(body)
      .unwrap()
      .then((response) => {
        dispatch(
          updateDataExportFileStatus({
            sessionId: response.sessionId,
            dataType: response.dataType,
            movementType: response.movementTypeId.toString(),
            status: "success",
            downloadUrl: response.downloadUrls[0],
          }),
        );
        if (response.downloadUrls[0] !== "") {
          window.location.assign(response.downloadUrls[0]);
        }
      })
      .catch(() => {
        dispatch(
          updateDataExportFileStatus({
            sessionId: file.sessionId,
            dataType: file.dataType.id,
            movementType: file.movementType.id,
            status: "error",
          }),
        );
      });
  };

  const closePopup = () => {
    if (exportInProgress) {
      window.alert(
        "An export is still in progress. Please wait for the export to complete before closing.",
      );
    } else {
      setExportPopupState({
        shouldOpen: false,
        title: "",
      });
      setExportInProgress(false);
      dispatch(clearDataExportFiles());
      if (minimizePopup) {
        setMinimizePopup(false);
      }
      if (showContent) {
        setShowContent(false);
      }
    }
  };

  useEffect(() => {
    if (exportPopupState.shouldOpen) {
      setShowContent(true);
    } else {
      sessionStorage.removeItem("exportPopupState");
      sessionStorage.removeItem("exportPopupTitle");
    }
  }, [exportPopupState]);

  useEffect(() => {
    if (exportPopupState.shouldOpen && exportInProgress) {
      makeApiRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPopupState, exportInProgress]);

  useEffect(() => {
    if (exportPopupState.shouldOpen && exportInProgress) {
      if (
        dataExportFiles.every(
          (file) => file.status === "success" || file.status === "error",
        )
      ) {
        setExportPopupState({
          ...exportPopupState,
          title: "Export Complete",
        });
        setExportInProgress(false);
        sessionStorage.setItem("exportPopupTitle", "Export Complete");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    exportPopupState.shouldOpen,
    exportInProgress,
    dataExportFiles,
    setExportPopupState,
    setExportInProgress,
  ]);

  return (
    <ReactModal
      isOpen={exportPopupState.shouldOpen}
      style={{
        overlay: {
          backgroundColor: "none",
          height: "0px",
          width: "0px",
        },
        content: {
          position: "fixed",
          maxWidth: "499px",
          maxHeight: "435px",
          inset: "auto 2% 3% auto",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          margin: "0",
          overflow: "scroll",
        },
      }}
    >
      <ModalHeader
        title={title}
        handleClose={closePopup}
        component="data-export-popup"
        minimizePopup={minimizePopup}
        toggleMinimize={toggleMinimize}
      />
      {showContent && (
        <div
          className="h-screen sm:h-full"
          data-testid="data-export-files-container"
        >
          <div
            className="h-full flex flex-col w-full overflow-scroll"
            data-testid="exporting-files-container"
          >
            {dataExportFiles.map((file, index) => {
              return (
                <div
                  className="flex w-full border-b border-b-gray-200 p-4 h-[87px] last:border-0 items-center"
                  data-testid="data-export-file"
                  key={index}
                >
                  <div
                    className="flex w-2/3 justify-start text-xs ml-2"
                    data-testid="export-file-details-container"
                  >
                    <div className="w-full flex flex-col">
                      <p
                        className="text-secondary-900 truncate w-5/6"
                        data-testid="export-session-id"
                      >
                        {file.sessionId}
                      </p>
                      <p className="text-[#4A4A4A] flex truncate w-5/6">
                        <span
                          className="mr-1"
                          data-testid="export-session-date"
                        >
                          {file.sessionDate},
                        </span>
                        <span data-testid="export-player-name">
                          {file.playerName}
                        </span>
                      </p>
                      <p className="text-[#979797] truncate w-5/6">
                        <span
                          className="mr-1"
                          data-testid="export-movement-type"
                        >
                          {file.movementType.value},
                        </span>
                        <span data-testid="export-data-type">
                          {file.dataType.value}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="flex w-1/3 justify-end mr-1"
                    data-testid="export-file-status-container"
                  >
                    <div data-testid="file-status-icon">
                      {((!file.status || file.status === "preparing") && (
                        <div
                          className="flex place-items-center"
                          data-testid="export-processing"
                        >
                          <p
                            className="text-[#979797] mr-2 text-xs"
                            data-testid="exporting-text"
                          >
                            Exporting Data...
                          </p>
                          <div className="w-9" data-testid="export-spinner">
                            <GradientProgressSpinner progress={null} />
                          </div>
                        </div>
                      )) ||
                        (file.status === "success" && (
                          <div
                            className="flex place-items-center"
                            data-testid="export-successful"
                          >
                            <a
                              className="text-gray-500 mr-4 text-xs"
                              data-testid="download-file-link"
                              href={file.downloadUrl}
                              download
                            >
                              Download
                            </a>
                            <StepCircleComplete
                              className="w-6 mr-1"
                              data-testid="green-check-circle"
                              id={`green-check-circle-${index}`}
                            />
                          </div>
                        )) ||
                        (file.status === "error" && (
                          <div
                            className="flex place-items-center"
                            data-testid="export-failed"
                          >
                            <button
                              className="text-gray-500 mr-4 text-xs"
                              data-testid="retry-export-button"
                              onClick={() => retryExport(file)}
                            >
                              Retry
                            </button>
                            <XIcon
                              className="w-6 mr-1"
                              data-testid="red-x-circle"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ReactModal>
  );
};
