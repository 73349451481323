import React, { useEffect, useRef } from "react";

import { BlankButton, ModalStepProgress, PrimaryButton } from "..";
import { useAppContext } from "../../context";
import { submitDataUpload } from "../../features/UploadData/services/ApiUploadFunctions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  usePatchSessionUploadMutation,
  usePostSessionUploadMutation,
} from "../../services/session";
import { FormTypeEnum } from "../../types";

export type StepProps = {
  goNextStep: () => void;
  goPreviousStep: () => void;
  currentStep: number;
  isLast: boolean;
  isFirst: boolean;
  step: number;
  display: JSX.Element;
  disableNext: boolean;
};

export type StepType = {
  title: string;
  display: JSX.Element;
  disableNext: boolean;
  formType: FormTypeEnum;
};

export type ModalStepProps = {
  steps: StepType[];
};

export const ModalStep: React.FC<ModalStepProps> = ({ steps }) => {
  // State and AppContext
  const {
    setPopupTitle,
    setPopupState,
    setModalState,
    uploadFiles,
    setCurrentStep,
    setAbortControllers,
    setUploadInProgress,
    setDialogState,
    currentStep,
    modalTitle,
  } = useAppContext();
  const [display, setDisplay] = React.useState<JSX.Element>(<></>);
  const [disableNext, setDisableNext] = React.useState<boolean>(true);
  const lastStep = steps.length;

  const stepSelector = useRef<HTMLDivElement>(null);

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  // RTK slice actions
  const dispatch = useAppDispatch();

  // RTK Queries and Mutations
  const [postSessionUpload] = usePostSessionUploadMutation();
  const [patchSessionUpload] = usePatchSessionUploadMutation();

  const dataUploadProps = {
    dispatch,
    uploadSessionFormValues,
    postSessionUpload,
    patchSessionUpload,
    setPopupTitle,
    setPopupState,
    setModalState,
    uploadFiles,
    setCurrentStep,
    setAbortControllers,
    setUploadInProgress,
    setDialogState,
  };

  const goNextStep = () => {
    const nextStep = currentStep + 1;
    if (steps && nextStep <= steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const goPreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 1) {
      setCurrentStep(previousStep);
    }
  };

  const handleButtonClick = () => {
    if (currentStep === lastStep && modalTitle.includes("Upload Data")) {
      submitDataUpload(dataUploadProps);
    } else {
      goNextStep();
    }
  };

  useEffect(() => {
    if (steps && steps.length > 0) {
      setDisplay(steps[currentStep - 1].display);
      setDisableNext(steps[currentStep - 1].disableNext);
    }
  }, [currentStep, steps]);

  return (
    <div
      data-testid="multi-step-modal-contents"
      className="w-full relative flex flex-col justify-between h-full"
    >
      <ModalStepProgress
        currentStep={currentStep}
        stepTitles={steps.map((mappedStep) => mappedStep.title)}
      />
      <div
        className="w-full h-full flex justify-center overflow-scroll"
        ref={stepSelector}
        data-testid="multi-step-modal-body"
      >
        {display}
      </div>
      <footer
        id="footer-container"
        className="flex justify-end pt-5 border-t border-gray-200 h-20 p-4"
        data-testid="multi-step-modal-footer"
      >
        <BlankButton
          data-testid={`back-modal-button-${currentStep}`}
          hidden={currentStep === 1}
          onClick={goPreviousStep}
        >
          Back
        </BlankButton>
        <PrimaryButton
          onClick={handleButtonClick}
          data-testid={`next-modal-button-${currentStep}`}
          className="ml-3"
          disabled={disableNext}
        >
          {currentStep === lastStep ? "Upload" : "Next"}
        </PrimaryButton>
      </footer>
    </div>
  );
};
