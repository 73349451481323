import { createColumnHelper, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import { filterSessionDates } from "../../components/Table/filters/SessionsTableFilter";
import { toTitleCase } from "../../shared/Functions";

export const columnHelper = createColumnHelper();

export const SharedSessionColumns = (): any[] => {
  return [
    columnHelper.accessor("sessionId", {
      header: "Session ID",
      enableSorting: false,
      cell: function idCell(element: any) {
        return (
          <div className="pr-6 truncate hover:text-clip hover:whitespace-normal hover:break-all">
            <Link
              to={`/session/${element.row.original.id}`}
              className="font-bold text-primary-700 hover:underline"
              data-testid="session-link"
            >
              {element.getValue()}
            </Link>
          </div>
        );
      },
    }),
    columnHelper.accessor("sessionDate", {
      header: "Session Date",
      sortingFn: "datetime",
      filterFn: filterSessionDates,
      cell: function dateCell(element: any) {
        return (
          <div data-testid="session-date">
            {format(new Date(element.getValue()), "M/d/yyyy")}
          </div>
        );
      },
    }),
    columnHelper.accessor("typeAndVenue", {
      header: "Session Label",
      sortingFn: (rowA: Row<any>, rowB: Row<any>) => {
        const typeA = rowA.original.typeAndVenue.split(", ")[0];
        const typeB = rowB.original.typeAndVenue.split(", ")[0];
        if (typeA > typeB) {
          return 1;
        }
        if (typeB > typeA) {
          return -1;
        }
        return 0;
      },
      sortDescFirst: false,
      cell: function typeAndVenueCell(element: any) {
        const value = element.getValue();
        const typeAndVenue = value.split(", ");
        const sessionType = typeAndVenue ? typeAndVenue[0] : "unknown";
        const sessionVenue = typeAndVenue ? typeAndVenue[1] : "unknown";
        return (
          <div className="flex flex-col">
            <div
              className="flex justify-begin items-center"
              data-testid="session-type"
            >
              <span className="text-gray-900 font-medium py-1" id={sessionType}>
                {toTitleCase(sessionType)}
              </span>
            </div>
            <div
              className="flex justify-begin items-center"
              data-testid="session-venue"
            >
              <span className="font-medium">
                {toTitleCase(
                  sessionVenue === "default" ? "Default Venue" : sessionVenue,
                )}
              </span>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("sessionTableValues.numberOfMovements", {
      header: "# of Movements",
      enableSorting: false,
      cell: function numberOfMovementsCell(element: any) {
        const movements =
          element.getValue() !== ""
            ? element.getValue().split(", ")
            : undefined;
        return (
          <div className="flex-col flex" data-testid="number-of-movements">
            {movements &&
              movements.map((movement: string, index: number) => {
                const splitMovement = movement.split(":");
                const movementType = splitMovement[0];
                const movementCount = splitMovement[1];
                return (
                  <div
                    className="flex justify-begin items-center"
                    data-testid="movement"
                    key={index}
                  >
                    <span
                      className="font-medium py-1 pr-1"
                      data-testid="movement-type"
                    >
                      {movementType}:
                    </span>
                    <p className="text-gray-900" data-testid="movement-count">
                      {movementCount}
                    </p>
                  </div>
                );
              })}
          </div>
        );
      },
    }),

    columnHelper.accessor("uploadDate", {
      header: "Upload Date",
      sortingFn: "datetime",
      cell: function dateCell(element: any) {
        return (
          <div className="font-medium text-gray-900" data-testid="upload-date">
            {format(new Date(element.getValue()), "M/d/yyyy")}
          </div>
        );
      },
    }),
    columnHelper.accessor("statusComponent", {
      header: "Status",
      enableSorting: false,
      cell: (info) => info.getValue(),
    }),
  ];
};
