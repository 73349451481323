import { Column } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import Select, { OptionTypeBase } from "react-select";

import {
  customSelectStyles,
  dateRangeOptions,
} from "../../../shared/Constants";
import { CustomDropdownWithCalendar } from "../../shared/CustomSelectComponents";

type DateRangeSelectProps = {
  filterValue: any;
  setDateFilter: React.Dispatch<React.SetStateAction<string>>;
  column: Column<Record<string, unknown>, unknown> | undefined;
};

export const SessionDateFilter: React.FC<DateRangeSelectProps> = ({
  filterValue,
  setDateFilter,
  column,
}: DateRangeSelectProps) => {
  const [dateDisplayed, setDateDisplayed] = useState<OptionTypeBase>({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (filterValue === "") {
      setDateDisplayed({ value: "", label: "All Dates" });
    }
  }, [filterValue]);

  return (
    <div className="w-40" data-testid="session-date-filter-container">
      <Select
        name="session-date-filter"
        aria-labelledby="session-date-filter-aria-label"
        inputId="session-date-filter"
        id="session-date-filter-select"
        options={[{ value: "", label: "All Dates" }, ...dateRangeOptions]}
        value={dateDisplayed.value !== "" ? dateDisplayed : null}
        isSearchable={false}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithCalendar,
          IndicatorSeparator: () => null,
        }}
        placeholder="Session Date"
        onChange={(option) => {
          setDateFilter(option.value);
          setDateDisplayed(option);
          if (option.value === "Custom Range") {
            column?.setFilterValue("");
          } else if (option.value === "Individual Dates") {
            column?.setFilterValue("");
          } else {
            column?.setFilterValue(option.value);
          }
        }}
      />
    </div>
  );
};
