import { createSlice } from "@reduxjs/toolkit";

import { Players } from "../../types/playersTypes";
import { RootState } from "../store";

const initialState: Players = {
  playerList: [],
};

export const playerSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    storePlayers: (state, action) => {
      state.playerList = action.payload;
    },
  },
});

export const selectPlayers = (state: RootState): Players => state.players;
