import {
  Auth0Client,
  Auth0ClientOptions,
  createAuth0Client,
  IdToken,
} from "@auth0/auth0-spa-js";
import React, { ReactNode, useContext, useEffect, useState } from "react";

/* This is a utility layer to allow access to certain
   functions needed for Redux outside of the auth0 sdk react hook
   and context provider.

   Currently exposed functions from useAuth0 hook we can access outside components include:
      isAuthenticated,
      user,
      getAccessTokenSilently
      logout

    These can be directly imported and used outside of components
    ```
    import { isAuthenticated, user, logout, getAccessTokenSilently } from "../shared/Auth0Utility";
    ```

    Import using the useAuth0 hook inside components.
    ```
    import { useAuth0 } from "../shared/Auth0Utility";
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    ```
*/

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

interface Auth0ContextProps {
  isAuthenticated: boolean | undefined;
  user: any;
  isLoading: boolean;
  popupOpen: boolean;
  getAccessTokenSilently: (...p: any[]) => Promise<string>;
  loginWithPopup: (params?: any) => Promise<void>;
  handleRedirectCallback: () => Promise<void>;
  getIdTokenClaims: (...p: any[]) => Promise<any>;
  loginWithRedirect: (...p: any[]) => Promise<void>;
  logout: (...p: any[]) => void;
}

const Auth0Context = React.createContext<Auth0ContextProps | undefined>(
  undefined,
);
export const useAuth0 = () => {
  const context = useContext(Auth0Context);
  if (!context) {
    throw new Error("useAuth0 must be used within an Auth0Provider");
  }
  return context;
};

let _initOptions: Auth0ClientOptions;
let client: Auth0Client | undefined;

export let exposedUser: any;
export let exposedIsAuthenticated: boolean | undefined;

export const getAccessTokenSilently = async (...p: any[]): Promise<string> => {
  if (!client) {
    client = await getAuth0Client();
  }
  return await client.getTokenSilently(...p);
};

export const logout = async (...p: any[]): Promise<void> => {
  if (!client) {
    client = await getAuth0Client();
  }
  return await client.logout(...p);
};

interface Auth0ProviderProps {
  children: ReactNode;
  domain: string;
  clientId: string;
  onRedirectCallback?: (appState: any) => void;
  [key: string]: any;
}

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );
  const [user, setUser] = useState<any>(undefined);
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions;
      if (!client) {
        client = await getAuth0Client();
      }
      setAuth0(client);
      if (window.location.search.includes("code=")) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const clientIsAuthenticated = await client.isAuthenticated();
      setIsAuthenticated(clientIsAuthenticated);
      exposedIsAuthenticated = clientIsAuthenticated;

      if (clientIsAuthenticated) {
        const clientUser = await client.getUser();
        setUser(clientUser);
        exposedUser = clientUser;
      }

      setIsLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const getIdTokenClaims = async (): Promise<IdToken | undefined> => {
    if (auth0Client) {
      return await auth0Client.getIdTokenClaims();
    }
    return undefined;
  };

  const loginWithRedirect = async (...p: any[]): Promise<void> => {
    if (auth0Client) {
      return await auth0Client.loginWithRedirect(...p);
    }
    return Promise.resolve();
  };

  const loginWithPopup = async (params: any = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const clientUser = await auth0Client?.getUser();
    setUser(clientUser);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setIsLoading(true);
    await auth0Client?.handleRedirectCallback();
    const clientUser = await auth0Client?.getUser();
    setIsLoading(false);
    setIsAuthenticated(true);
    setUser(clientUser);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: () => getIdTokenClaims(),
        loginWithRedirect: (...p: any[]) => loginWithRedirect(...p),
        logout: (...p: any[]) => auth0Client?.logout(...p),
        getAccessTokenSilently: (...p: any[]) => getAccessTokenSilently(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

const getAuth0Client = (): Promise<Auth0Client> => {
  return new Promise(async (resolve, reject) => {
    if (client) {
      resolve(client);
    } else {
      try {
        client = await createAuth0Client(_initOptions);
        resolve(client);
      } catch (error) {
        reject(new Error("getAuth0Client Error: " + error));
      }
    }
  });
};

export { exposedUser as user };
export { exposedIsAuthenticated as isAuthenticated };
