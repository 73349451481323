import { createSlice } from "@reduxjs/toolkit";

import { Player } from "../../types";

type PlayerGroupType = {
  playerGroupList: Player[];
  modalOpenState: boolean;
  groupName: string;
  groupId?: number;
};

const initialState: PlayerGroupType = {
  playerGroupList: [],
  modalOpenState: false,
  groupName: "",
  groupId: undefined,
};

export const playerGroupSlice = createSlice({
  name: "playerGroup",
  initialState,
  reducers: {
    storePlayerGroup: (state, action) => {
      state.playerGroupList = action.payload.playerGroupList;
      state.modalOpenState = action.payload.modalOpenState;
      state.groupName = action.payload.groupName;
      state.groupId = action.payload.groupId;
    },
    clearPlayerGroup: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: () => ({}),
});
