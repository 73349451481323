import { Styles } from "react-modal";

export const mobileModalStyles: Styles = {
  overlay: {
    paddingTop: "0px",
    marginBottom: "0px",
  },
  content: {
    inset: "0px",
    margin: "0px auto 200px",
    width: "100vw",
    height: "100vh",
    maxWidth: "100vw",
    backgroundColor: "white",
    borderRadius: "0",
    position: "fixed",
  },
};

export const popupStyles: Styles = {
  overlay: {
    backgroundColor: "none",
    height: "0px",
    width: "0px",
  },
  content: {
    position: "fixed",
    maxWidth: "24rem",
    maxHeight: "400px",
    inset: "auto 2% 3% auto",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    margin: "0",
    overflow: "scroll",
  },
};

export const mobilePopupStyles: Styles = {
  overlay: {
    backgroundColor: "none",
    height: "0px",
    width: "0px",
  },
  content: {
    position: "fixed",
    inset: "0px",
    margin: "0px auto 200px",
    width: "100vw",
    height: "100vh",
    maxWidth: "100vw",
    backgroundColor: "white",
    borderRadius: "0",
  },
};
