import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

import { UploadSessionFormValues } from "../../types";

export const initialState: UploadSessionFormValues = {
  sessionDate: format(new Date(), "yyyy-MM-dd"),
  selectedPlayer: { value: "", label: "Select Player" },
  sessionType: { value: "", label: "Select Session Type" },
  sessionVenue: { value: "", label: "Select Venue" },
  movementType: { value: "", label: "Select Movement Type" },
  movementSubType: { value: "", label: "" },
  movementTags: [],
  files: [],
  orgId: "",
  firstDrop: true,
  frameRate: "",
};

export const uploadSessionFormSlice = createSlice({
  name: "uploadSessionForm",
  initialState,
  reducers: {
    storeUploadSessionFormState: (state, action) => {
      state.sessionDate = action.payload.sessionDate;
      state.selectedPlayer = action.payload.selectedPlayer;
      state.sessionType = action.payload.sessionType;
      state.sessionVenue = action.payload.sessionVenue;
      state.movementType = action.payload.movementType;
      state.movementSubType = action.payload.movementSubType;
      state.movementTags = action.payload.movementTags;
      state.files = action.payload.files;
      state.orgId = action.payload.orgId;
      state.firstDrop = action.payload.firstDrop;
      state.frameRate = action.payload.frameRate;
    },
    clearUploadSessionFormState: (state) => {
      state = initialState;
      return state;
    },
  },
});
