import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export type ProgressBarProps = {
  progress: number;
};

export const ProgressBar = ({ progress }: ProgressBarProps): JSX.Element => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    setProgressValue(progress);
  }, [progress]);

  return (
    <>
      {progress > 0 ? (
        <div className="justify-end md:justify-start mt-4 flex w-1/4 lg:w-full">
          <div className="hidden md:block relative pt-1 md:w-5/6">
            <div
              className="overflow-hidden h-7 mb-4 text-xs flex rounded-full bg-white border border-1 border-gray-400"
              data-testid="progress-bar-container"
            >
              <div
                style={{
                  width: `${progressValue}%`,
                  transitionDuration: "1.5s",
                }}
                className="transition-all ease-in delay-300 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-secondary-800 rounded-full"
              >
                <div className="text-right pr-2">
                  <span
                    className="text-xs font-semibold inline-block text-white"
                    data-testid="progress-bar-percentage-container"
                  >
                    {progress > 10 ? `${progress}%` : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex mb-2 items-center justify-between"></div>
          </div>

          <div
            className="w-9 md:hidden"
            data-testid="mobile-circular-progress-bar-container"
          >
            <CircularProgressbar
              value={progress}
              text={`${progress}%`}
              strokeWidth={10}
              styles={{
                root: {},
                path: {
                  stroke: "#FB6A07",
                  strokeLinecap: "butt",
                },
                trail: {
                  stroke: "#D8D8D8",
                  strokeLinecap: "butt",
                },
                text: {
                  fill: "black",
                  fontSize: "24px",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
        </div>
      ) : (
        <p className="" data-testid="awaiting-processing-status">
          Awaiting Processing
        </p>
      )}
    </>
  );
};
