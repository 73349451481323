import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import ReactModal from "react-modal";

import {
  ErrorToast,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SuccessToast,
} from "../../components";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { playerGroupSlice } from "../../redux/slices/playerGroupSlice";
import {
  useCreatePlayerGroupMutation,
  useGetPlayerGroupByIdQuery,
  useUpdatePlayerGroupMutation,
} from "../../services";
import { Player } from "../../types";
import { PlayerGroupStats } from "./PlayerGroupStats";

export const PlayerGroupModal: React.FC = (): JSX.Element => {
  const title = "Player Group Details";

  // State and app context
  const { setDialogState } = useAppContext();
  const [searchValue, setSearchValue] = useState("");
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [availablePlayers, setAvailablePlayers] = useState<Player[]>([]);
  const [populatePlayersInGroup, setPopulatePlayersInGroup] = useState(false);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayerGroup, clearPlayerGroup } = playerGroupSlice.actions;

  // Redux calls
  const playerList = useAppSelector((state) => state.players.playerList);
  const playerGroupState = useAppSelector((state) => state.playerGroup);

  const groupIdToEdit = playerGroupState.groupId;
  const modalState = playerGroupState.modalOpenState;

  // RTK Mutations and Query
  const [createPlayerGroup] = useCreatePlayerGroupMutation();
  const [updatePlayerGroup] = useUpdatePlayerGroupMutation();
  const { data: playersInGroup, isFetching: fetchingPlayersInGroup } =
    useGetPlayerGroupByIdQuery(groupIdToEdit ? groupIdToEdit : undefined, {
      skip: !groupIdToEdit,
    });

  useEffect(() => {
    if (groupIdToEdit && !fetchingPlayersInGroup && playersInGroup) {
      dispatch(
        storePlayerGroup({
          ...playerGroupState,
          playerGroupList: playersInGroup,
        }),
      );
      setPopulatePlayersInGroup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playersInGroup, groupIdToEdit]);

  useEffect(() => {
    if (populatePlayersInGroup) {
      addPlayersToGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populatePlayersInGroup]);

  const toastSuccess = (message: string) =>
    toast.custom(
      <SuccessToast message={message} classNames="mt-modal w-1/3" />,
      {
        duration: 2000,
      },
    );
  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
      id: "playerGroupError",
    });

  window.onbeforeunload = () => {
    dispatch(clearPlayerGroup());
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const closeModal = () => {
    dispatch(clearPlayerGroup());
    setSelectedPlayers([]);
    setSearchValue("");
    setPopulatePlayersInGroup(false);
  };

  const handleCheckboxChange = (checkedPlayer: Player) => {
    const checkedPlayerIndex = playerGroupState.playerGroupList.findIndex(
      (player: Player) => player.id === checkedPlayer.id,
    );

    if (checkedPlayerIndex !== -1) {
      const updatedSelectedPlayers = playerGroupState.playerGroupList.filter(
        (player: Player) => player.id !== checkedPlayer.id,
      );
      dispatch(
        storePlayerGroup({
          ...playerGroupState,
          playerGroupList: updatedSelectedPlayers,
        }),
      );
    } else {
      dispatch(
        storePlayerGroup({
          ...playerGroupState,
          playerGroupList: [...playerGroupState.playerGroupList, checkedPlayer],
        }),
      );
    }
  };

  const addPlayersToGroup = () => {
    setSelectedPlayers(playerGroupState.playerGroupList);

    const updatedAvailablePlayers = availablePlayers.filter(
      (player: Player) =>
        !playerGroupState.playerGroupList.some(
          (selectedPlayer: Player) => selectedPlayer.id === player.id,
        ),
    );
    setAvailablePlayers(updatedAvailablePlayers);
  };

  const removePlayerFromGroup = (playerIdToDelete: string) => {
    const updatedSelectedPlayers = selectedPlayers.filter(
      (player: Player) => player.orgPlayerId !== playerIdToDelete,
    );
    dispatch(
      storePlayerGroup({
        ...playerGroupState,
        playerGroupList: updatedSelectedPlayers,
      }),
    );
    setSelectedPlayers(updatedSelectedPlayers);

    const removedPlayer = selectedPlayers.find(
      (player: Player) => player.orgPlayerId === playerIdToDelete,
    );
    if (removedPlayer) {
      setAvailablePlayers([...availablePlayers, removedPlayer]);
    }
  };

  useEffect(() => {
    const alphabetizedPlayers = [...availablePlayers].sort(
      (a: Player, b: Player) => (a.lastName < b.lastName ? -1 : 1),
    );
    setAvailablePlayers(alphabetizedPlayers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePlayers.length]);

  useEffect(() => {
    if (playerList.length > 0) {
      setAvailablePlayers(playerList);
    }
  }, [playerList]);

  useEffect(() => {
    if (searchValue !== "") {
      const filteredAvailablePlayers = availablePlayers.filter((player) => {
        return (
          player.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          player.lastName.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setAvailablePlayers(filteredAvailablePlayers);
    } else {
      const filteredPlayerList = playerList.filter(
        (player: Player) =>
          !selectedPlayers.some(
            (selectedPlayer: Player) =>
              selectedPlayer.orgPlayerId === player.orgPlayerId,
          ),
      );
      setAvailablePlayers(filteredPlayerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerList, searchValue]);

  const handleSavePlayerGroup = () => {
    const playerIds = playerGroupState.playerGroupList.map(
      (player: Player) => player.id,
    );
    const body = {
      name: playerGroupState.groupName,
      player_ids: playerIds,
    };

    if (playerGroupState.groupId) {
      updatePlayerGroup({ id: playerGroupState.groupId, body })
        .unwrap()
        .then(() => {
          closeModal();
          toastSuccess("Player Group Successfully Saved!");
        })
        .catch(() => {
          toastError(
            "There was an issue saving the player group. Please try again.",
          );
        });
      return;
    } else {
      createPlayerGroup(body)
        .unwrap()
        .then(() => {
          closeModal();
          toastSuccess("Player Group Successfully Saved!");
        })
        .catch(() => {
          toastError(
            "There was an issue saving the player group. Please try again.",
          );
        });
    }
  };

  return (
    <ReactModal
      isOpen={modalState}
      style={{
        content: {
          width: "100vw",
          height: "100vh",
          position: "fixed",
          maxWidth: "100vw",
          inset: "0px",
          margin: "0px",
          borderRadius: "0",
        },
      }}
    >
      <div
        className="flex flex-col h-screen"
        data-testid="player-group-modal-container"
      >
        <ModalHeader
          title={title}
          handleClose={closeModal}
          component="player-group"
        />

        <div className="grid grid-cols-7 gap-12 w-full h-[90%] px-4 sm:px-6 lg:px-8 mt-8 pb-10 overflow-scroll">
          <div
            className="col-span-2"
            data-testid="player-group-details-container"
          >
            <div
              className="flex flex-col justify-start lg:mb-6"
              data-testid="group-name-container"
            >
              <div className="flex justify-between">
                <h2
                  className="text-3xl leading-6 text-[#979797] truncate pt-2"
                  data-testid="group-name"
                >
                  {playerGroupState.groupName}
                </h2>
                <button
                  data-testid="edit-group-name-button"
                  onClick={(event) => {
                    event.preventDefault();
                    setDialogState({
                      shouldOpen: true,
                      type: "group",
                    });
                  }}
                >
                  <PencilIcon className="w-5 ml-4 mb-4 pt-2" />
                </button>
              </div>
            </div>
            <PlayerGroupStats selectedPlayers={selectedPlayers} />
          </div>

          <div
            className="col-span-2 border-2 border-gray-200 rounded-md shadow-lg overflow-hidden"
            data-testid="available-players-container"
          >
            <div className="text-[#979797] border-b border-gray-100 p-4 h-14">
              <p>Available Players</p>
            </div>
            <div
              className="p-2 bg-[#F9FAFB]"
              data-testid="players-search-container"
            >
              <div
                className="flex flex-col justify-center items-center static w-full border border-[#D1D5DB] rounded-lg shadow-sm"
                data-testid="players-search-bar"
              >
                <div className="relative w-full">
                  <div
                    className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-2 text-gray-900"
                    aria-hidden="true"
                  >
                    <MagnifyingGlassIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                      id="search-icon"
                      data-testid="search-icon"
                    />
                  </div>
                  <input
                    id="search-field"
                    name="search_field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-400 bg-white rounded-lg focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleInput}
                    type="search"
                    data-testid="player-group-search-input"
                  />
                </div>
              </div>
            </div>
            <div
              className="h-[90%] overflow-scroll pb-10"
              data-testid="available-players-list"
            >
              {availablePlayers.map((player: Player, index: number) => {
                return (
                  <div
                    className="flex items-center border-b border-gray-100 p-4"
                    key={index}
                    id={player.orgPlayerId}
                    data-testid="available-player"
                  >
                    <input
                      type="checkbox"
                      className="border-gray-300 rounded-[4px] mr-4 checked:bg-[#FB6A07] checked:focus:bg-[#FB6A07] checked:hover:bg-[#FB6A07] focus:ring-[#FB6A07] focus:ring-opacity-50 focus:ring-2"
                      id="player-checkbox-input"
                      name="player-checkbox-input"
                      data-testid="player-checkbox-input"
                      onChange={() => handleCheckboxChange(player)}
                      checked={playerGroupState.playerGroupList.includes(
                        player,
                      )}
                    ></input>
                    <label
                      htmlFor="player-checkbox-input"
                      className="flex flex-col text-sm pointer-events-none"
                      data-testid="player-checkbox-label"
                    >
                      <span className="text-gray-700">
                        {`${player.firstName} ${player.lastName}`}
                      </span>
                      <span className="mt-[2px] text-gray-400">
                        {`DOB: ${player.dateOfBirth}`}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="h-full flex justify-center items-center"
            data-testid="add-players-button-container"
          >
            <button
              className="bg-[#FB6A07] text-white rounded-md shadow-sm h-16 w-14 flex flex-col justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed"
              data-testid="add-players-button"
              disabled={playerGroupState.playerGroupList.length === 0}
              onClick={addPlayersToGroup}
            >
              <ArrowRightIcon className="w-5 h-6" />
              <span>Add</span>
            </button>
          </div>

          <div
            className="col-span-2 border-2 border-gray-200 rounded-md shadow-lg overflow-hidden"
            data-testid="player-group-list-container"
          >
            <div className="text-[#979797] border-b border-gray-100 p-4 h-14">
              <p>Player Group</p>
            </div>
            <div className="h-full">
              {selectedPlayers.length > 0 ? (
                <div
                  className="h-full overflow-scroll"
                  data-testid="player-group-list"
                >
                  {[...selectedPlayers]
                    .sort((a: Player, b: Player) =>
                      a.lastName < b.lastName ? -1 : 1,
                    )
                    .map((player: Player, index: number) => {
                      return (
                        <div
                          className="flex justify-between items-center border-b border-gray-100 p-4"
                          key={index}
                          id={player.orgPlayerId}
                          data-testid="player-group-list-player"
                        >
                          <div className="flex flex-col text-sm">
                            <p className="text-gray-700">
                              {`${player.firstName} ${player.lastName}`}
                            </p>
                            <p className="mt-[2px] text-gray-400">
                              {`DOB: ${player.dateOfBirth}`}
                            </p>
                          </div>
                          <button
                            data-testid="trash-button"
                            onClick={() =>
                              removePlayerFromGroup(player.orgPlayerId)
                            }
                          >
                            <TrashIcon className="w-5 text-gray-900" />
                          </button>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div
                  className="h-[90%] flex justify-center items-center"
                  data-testid="create-player-group-helper-text"
                >
                  <p className="text-xl text-[#979797] text-center px-16">
                    Select players and click add to create a player group.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <footer className="h-[20%]" data-testid="player-group-modal-footer">
          <div className="h-20 w-full bg-gray-50 flex justify-end items-center px-6 py-7 bottom-0 fixed">
            <SecondaryButton
              className="w-24"
              data-testid="player-group-modal-cancel-button"
              onClick={closeModal}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              className="ml-4"
              onClick={handleSavePlayerGroup}
              data-testid="save-player-group-button"
              disabled={selectedPlayers.length === 0}
            >
              Save Player Group
            </PrimaryButton>
          </div>
        </footer>
      </div>
    </ReactModal>
  );
};
