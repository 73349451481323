import { Header } from "@tanstack/react-table";
import React, { useState } from "react";
import Select, { OptionTypeBase } from "react-select";

import { useAppSelector } from "../../../redux/hooks";
import { customSelectStyles } from "../../../shared/Constants";
import { CustomDropdownWithChevron } from "../../shared/CustomSelectComponents";

type SessionTypeFilterProps = {
  sessionTypeHeader?: Header<Record<string, unknown>, unknown>;
};

export const SessionTypeFilter: React.FC<SessionTypeFilterProps> = ({
  sessionTypeHeader,
}: SessionTypeFilterProps) => {
  const orgData = useAppSelector((state) => state.org);

  const [sessionTypeDisplayed, setSessionTypeDisplayed] =
    useState<OptionTypeBase>({
      value: "",
      label: "",
    });

  const sessionTypes = orgData.details.sessionTypes
    ? orgData.details.sessionTypes.map((sessionType) => {
        const slug = sessionType.slug.includes("-")
          ? sessionType.slug.replace(/-/g, " ")
          : sessionType.slug;
        return { value: `${slug}`, label: `${sessionType.name}` };
      })
    : [];

  const sessionTypeOptions = [
    ...sessionTypes,
    { value: "", label: "All Types" },
  ].sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="w-40 ml-2" data-testid="session-type-filter-container">
      <Select
        inputId="session-type-filter"
        id="session-type-filter-select"
        options={sessionTypeOptions}
        value={sessionTypeDisplayed.value !== "" ? sessionTypeDisplayed : null}
        isSearchable={false}
        styles={customSelectStyles}
        placeholder="Session Type"
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        onChange={(sessionType) => {
          setSessionTypeDisplayed(sessionType);
          sessionTypeHeader?.column.setFilterValue(sessionType.value);
        }}
      />
    </div>
  );
};
