import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  ColumnOneTableCellSkeleton,
  SecondaryButton,
  Table,
  TableCellSkeleton,
} from "../../components";
import { columnHelper } from "../../components/shared/SharedSessionColumns";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { requestedAnalysisFormSlice } from "../../redux/slices/requestedAnalysisFormSlice";
import {
  useGetRequestedAnalysesQuery,
  useGetRequestedAnalysisByIdQuery,
} from "../../services";
import { joinClassNames } from "../../shared/Functions";

export const RequestedAnalyses: React.FC = (): JSX.Element => {
  // State and AppContext
  const { setDisplayBack, setDialogState } = useAppContext();
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("processedSessionPageSize") || "10",
  );
  const [requestedAnalysisId, setRequestedAnalysisId] = useState<number>(0);

  // RTK Queries
  const { data: requestedAnalyses, isFetching } =
    useGetRequestedAnalysesQuery();
  const { data: singleAnalysis } = useGetRequestedAnalysisByIdQuery(
    requestedAnalysisId,
    {
      skip: requestedAnalysisId === 0,
    },
  );

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeRequestedAnalysisFormState } =
    requestedAnalysisFormSlice.actions;

  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );
  const mocapTypes = useAppSelector((state) => state.org.details.mocapTypes);
  const playerList = useAppSelector((state) => state.players.playerList);

  const { push } = useHistory();

  const determineCriteriaForSelectedAnalysis = (segmentCriteria: any) => {
    const criteria: any[] = [];
    if (segmentCriteria.mocapTypeId) {
      const mocapType = mocapTypes.find((type) => {
        return type.id === segmentCriteria.mocapTypeId;
      });
      criteria.push({
        comparison: {
          value: "is",
          label: "is",
        },
        field: {
          value: "mocapType",
          label: "Mocap Type",
        },
        option: {
          value: mocapType?.slug,
          label: mocapType?.name,
          id: mocapType?.id,
        },
      });
    }
    if (segmentCriteria.orgPlayerIds?.length) {
      const playerOptions: any[] = [];
      segmentCriteria.orgPlayerIds.forEach((id: string) => {
        playerList.find((playerObj) => {
          if (playerObj.orgPlayerId === id) {
            playerOptions.push({
              value: `${playerObj.firstName} ${playerObj.lastName}`,
              label: `${playerObj.firstName} ${playerObj.lastName}`,
              id: playerObj.orgPlayerId,
            });
          }
        });
      });
      if (playerOptions.length) {
        criteria.push({
          comparison: {
            value: "includes",
            label: "includes",
          },
          field: {
            value: "player",
            label: "Player",
          },
          option: playerOptions,
        });
      }
    }
    if (segmentCriteria.pitchTypes?.length) {
      const pitchTypeOptions: any[] = [];
      segmentCriteria.pitchTypes.forEach((pitchType: string) => {
        pitchTypeOptions.push({
          value: pitchType,
          label: pitchType,
        });
      });
      criteria.push({
        comparison: {
          value: "is",
          label: "is",
        },
        field: {
          value: "pitchType",
          label: "Pitch Type",
        },
        option: pitchTypeOptions,
      });
    }
    if (segmentCriteria.mocapPitchTypes?.length) {
      const mocapPitchTypeOptions: any[] = [];
      segmentCriteria.mocapPitchTypes.forEach((mocapPitchType: string) => {
        mocapPitchTypeOptions.push({
          value: mocapPitchType,
          label: mocapPitchType,
        });
      });
      criteria.push({
        comparison: {
          value: "is",
          label: "is",
        },
        field: {
          value: "mocapPitchType",
          label: "Mocap Pitch Type",
        },
        option: mocapPitchTypeOptions,
      });
    }
    return criteria;
  };

  useEffect(() => {
    if (singleAnalysis) {
      const mocapType = mocapTypes.find((type) => {
        return type.id === singleAnalysis.mocapTypeId;
      });

      const analysisToEdit = {
        ...requestedAnalysisFormState,
        analysisId: singleAnalysis.id,
        name: singleAnalysis.name,
        hand: singleAnalysis.domHand,
        movementType: singleAnalysis.movementType,
        mocapType: mocapType
          ? {
              value: mocapType.slug,
              label: mocapType.name,
              id: mocapType.id,
            }
          : null,
        primarySegment: {
          dateCriteria: singleAnalysis.primarySegment.dateCriteria,
          dateRange: singleAnalysis.primarySegment.dateRange,
          criteria: determineCriteriaForSelectedAnalysis(
            singleAnalysis.primarySegment.criteria,
          ),
          id: singleAnalysis.primarySegment.id,
        },
      };

      if (singleAnalysis.populationSegment) {
        Object.assign(analysisToEdit, {
          populationSegment: {
            dateCriteria: singleAnalysis.populationSegment.dateCriteria,
            dateRange: singleAnalysis.populationSegment.dateRange,
            criteria: determineCriteriaForSelectedAnalysis(
              singleAnalysis.populationSegment.criteria,
            ),
            id: singleAnalysis.populationSegment.id,
          },
        });
      } else if (singleAnalysis.comparisonSegment) {
        Object.assign(analysisToEdit, {
          comparisonSegment: {
            dateCriteria: singleAnalysis.comparisonSegment.dateCriteria,
            dateRange: singleAnalysis.comparisonSegment.dateRange,
            criteria: determineCriteriaForSelectedAnalysis(
              singleAnalysis.comparisonSegment.criteria,
            ),
            id: singleAnalysis.comparisonSegment.id,
          },
        });
      }
      dispatch(storeRequestedAnalysisFormState(analysisToEdit));
      push("/request-analysis");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAnalysis]);

  const parseAnalysisData = (data: any) => {
    return data.map((analysis: any) => {
      const getAnalysisStatus = () => {
        if (analysis.status === "completed") {
          return "Complete";
        } else if (analysis.status === "in_progress") {
          return "In-progress";
        } else if (analysis.status === "draft") {
          return "Draft";
        } else if (analysis.status === "requested") {
          return "Requested";
        } else if (analysis.status === "failed") {
          return "Failed";
        } else {
          return "";
        }
      };
      const getActions = () => {
        if (analysis.status === "completed") {
          return "Analysis";
        } else if (analysis.status === "draft") {
          return "Edit";
        } else {
          return "";
        }
      };
      return {
        ...analysis,
        status: getAnalysisStatus(),
        actions: getActions(),
      };
    });
  };

  const requestedAnalysesTableData = parseAnalysisData(requestedAnalyses || []);

  const requestedAnalysesColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Analysis Name",
        cell: function nameCell(element: any) {
          return (
            <p
              className="font-bold text-primary-700 cursor-default"
              data-testid="requested-analysis-name"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Date Created",
        sortingFn: "datetime",
        cell: function dateCell(element: any) {
          return (
            <div
              className="font-medium text-gray-900 cursor-default"
              data-testid="requested-analysis-date-created"
            >
              {format(new Date(element.getValue()), "M/d/yyyy")}
            </div>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: function statusCell(element: any) {
          return (
            <p
              className={joinClassNames(
                element.getValue() === "Complete" ? "text-green-600" : "",
                "cursor-default",
              )}
              data-testid="requested-analysis-status"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        cell: function actionsCell(element: any) {
          const url: string = element.row.original.reportUrl;
          const id = element.row.original.id;
          return (
            <div
              className="flex"
              data-testid="requested-analysis-actions-container"
            >
              {element.getValue() === "Analysis" && url && (
                <>
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    data-testid="requested-analysis-link"
                  >
                    <SecondaryButton data-testid="view-requested-analysis-button">
                      View
                    </SecondaryButton>
                  </a>
                  <SecondaryButton
                    data-testid="re-request-requested-analysis-button"
                    className="ml-4"
                    onClick={() => setRequestedAnalysisId(id)}
                  >
                    Request Again
                  </SecondaryButton>
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [],
  );

  const tableData = useMemo(
    () => (isFetching ? Array(10).fill({}) : requestedAnalysesTableData),
    [isFetching, requestedAnalysesTableData],
  );

  const tableColumns = useMemo(
    () =>
      isFetching
        ? requestedAnalysesColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : requestedAnalysesColumns,
    [isFetching, requestedAnalysesColumns],
  );

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
  }, [setDisplayBack]);

  const buttons = [
    {
      type: "primary",
      text: "Build New Analysis",
      action: () => setDialogState({ shouldOpen: true, type: "analysis" }),
      dataTestid: "create-analysis-button",
    },
    {
      type: "secondary",
      text: "Advanced",
      action: () => {
        const newWindow = window.open(
          "https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples_api/RebootMotionCustomReport.ipynb",
          "_blank",
          "noopener,noreferrer",
        );
        if (newWindow) newWindow.opener = null;
      },
      dataTestid: "advanced-analysis-button",
      Icon: ArrowTopRightOnSquareIcon,
    },
  ];

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full mt-8">
      <div
        className="block min-w-max"
        data-testid="requested-analyses-table-container"
      >
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="requested-analyses-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={isFetching}
          customRowClassName="requested-analyses-table-row"
          component="requestedAnalyses"
          buttons={buttons}
        />
      </div>
    </main>
  );
};
