import {
  ArrowTrendingUpIcon,
  ArrowUpTrayIcon,
  ChartBarSquareIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useHistory } from "react-router-dom";

import { QuickActionButton } from "../..";
import { QuickActionsSkeleton } from "../../../components";
import { useAppContext } from "../../../context";
import { useAppSelector } from "../../../redux/hooks";
import { disableCustomAnalysis } from "../../../shared/Functions";

type QuickActionProps = {
  loadingDashboard: boolean;
  openModal: (type: string) => void;
};

export const QuickActions: React.FC<QuickActionProps> = ({
  loadingDashboard,
  openModal,
}: QuickActionProps): JSX.Element => {
  const { push } = useHistory();
  const { setDialogState } = useAppContext();

  const orgType = useAppSelector((state) => state.org.type);

  return (
    <>
      {loadingDashboard ? (
        <QuickActionsSkeleton orgType={orgType} />
      ) : (
        <div
          className="mt-4 sm:px-6 lg:px-8 lg:pb-6"
          data-testid="quick-actions-container"
        >
          <h2
            className="py-4 text-3xl leading-6 font-bold text-gray-900"
            data-testid="quick-actions-header"
          >
            Quick Actions
          </h2>
          <div
            className="max-w-8xl pt-4 flex flex-wrap"
            data-testid="quick-action-buttons"
          >
            {!disableCustomAnalysis(orgType) && (
              <QuickActionButton
                action="Custom Analysis"
                Icon={ChartBarSquareIcon}
                buttonDataTestid="custom-analysis-action-button"
                handleClick={() =>
                  setDialogState({ shouldOpen: true, type: "analysis" })
                }
              />
            )}
            <QuickActionButton
              action="Upload Data"
              Icon={ArrowUpTrayIcon}
              buttonDataTestid="upload-data-action-button"
              handleClick={() => openModal("upload")}
            />
            <QuickActionButton
              action="Biomechanics Simulation"
              Icon={ComputerDesktopIcon}
              buttonDataTestid="biomechanics-simulation-action-button"
              handleClick={() => push("/analyze/biomechanics-simulation")}
            />
            <QuickActionButton
              action="Analyze Time Series"
              Icon={ClockIcon}
              buttonDataTestid="time-series-action-button"
              handleClick={() => push("/analyze/time-series")}
            />
            <QuickActionButton
              action="Analyze Metrics"
              Icon={ArrowTrendingUpIcon}
              buttonDataTestid="metrics-action-button"
              handleClick={() => push("/analyze/metrics")}
            />
            <QuickActionButton
              action="Reboot Recommendations"
              Icon={ClipboardDocumentCheckIcon}
              buttonDataTestid="recommendations-action-button"
              handleClick={() => push("/analyze/recommendations")}
            />
          </div>
        </div>
      )}
    </>
  );
};
