import { camelCaseResponse, providesList } from "../shared/Functions";
import { emptyApi } from "./emptyApi";
import { parsePlayerData } from "./player";

export const playerGroupExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayerGroups: builder.query<any, void>({
      query: () => "/player_group",
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "PlayerGroups"),
    }),
    createPlayerGroup: builder.mutation({
      query: (body) => ({
        url: "/player_group",
        method: "POST",
        body,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["PlayerGroups"],
    }),
    getPlayerGroupById: builder.query<any, number | undefined>({
      query: (id) => `/players_in_group/${id}`,
      transformResponse: (response: any) =>
        parsePlayerData(camelCaseResponse(response)),
      providesTags: (results) => providesList(results, "PlayerGroupById"),
    }),
    updatePlayerGroup: builder.mutation({
      query: ({ id, body }) => ({
        url: `/player_group/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["PlayerGroups", "PlayerGroupById"],
    }),
  }),
});

export const {
  useGetPlayerGroupsQuery,
  useCreatePlayerGroupMutation,
  useGetPlayerGroupByIdQuery,
  useUpdatePlayerGroupMutation,
} = playerGroupExtendedApi;
