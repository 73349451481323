import { User } from "@auth0/auth0-react";
import { createSlice } from "@reduxjs/toolkit";
import { addHours } from "date-fns";
import storage from "redux-persist/lib/storage";

import { RootState } from "../store";

export interface AuthUser extends User {
  token: string;
}

const initialState: AuthUser = {
  email: "",
  emailVerified: false,
  name: "",
  nickname: "",
  id: "",
  updatedAt: "",
  token: "",
  permissions: [],
  loginTime: "",
  tokenExpiry: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserCredentials: (state, action) => {
      state.email = action.payload.email;
      state.emailVerified = action.payload.email_verified;
      state.name = action.payload.name;
      state.nickname = action.payload.nickname;
      state.id = action.payload.sub;
      state.updatedAt = action.payload.updated_at;
      state.loginTime = new Date().toISOString();
      state.tokenExpiry = addHours(new Date(), 23).toISOString();
    },
    setUserAuth: (state, action) => {
      state.token = action.payload.token;
      state.permissions = action.payload.permissions;
    },
    clearUserState: () => {
      storage.removeItem("persist:root");
      sessionStorage.clear();
    },
  },
});

export const selectUser = (state: RootState): AuthUser => state.user;
