import { Square2StackIcon } from "@heroicons/react/24/outline";
import React from "react";
import { toast } from "react-hot-toast";

import { PrimaryButton, SuccessToast } from "../../../components";
import { useAppSelector } from "../../../redux/hooks";

export const Credentials: React.FC = (): JSX.Element => {
  const awsCredentials = useAppSelector(
    (state) => state.awsCredentials.credentialsString,
  );

  const successToast = (message: string) =>
    toast.custom(
      <SuccessToast message={message} classNames="mt-modal w-1/3" />,
    );

  const copyAwsCredentials = () => {
    navigator.clipboard.writeText(awsCredentials);
    successToast("Successfully Copied!");
  };

  return (
    <div
      className="relative mt-8 pb-20 z-0 overflow-y-auto w-full px-8 flex justify-center"
      data-testid="aws-credentials-container"
    >
      <div className="max-w-[60rem] w-full flex flex-col justify-center mt-4">
        <p className="text-[#6B7280]" data-testid="credentials-helper-text">
          Copy these credentials and paste them into the notebook when prompted.
        </p>
        <label
          htmlFor="aws-credentials"
          className="sr-only"
          id="screen-reader-aws-credentials-label"
          aria-hidden="true"
        >
          AWS Credentials
        </label>
        <textarea
          id="aws-credentials"
          name="aws-credentials"
          value={awsCredentials}
          readOnly
          className="border-gray-300 rounded-md text-gray-900 mt-8 h-[186px]"
          data-testid="aws-credentials-textarea"
        />
        <div className="mt-8 flex justify-center">
          <PrimaryButton
            className="flex justify-center items-center mr-8"
            data-testid="copy-aws-credentials-button"
            onClick={copyAwsCredentials}
          >
            <Square2StackIcon className="w-6 mr-1" />
            Copy Credentials
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
