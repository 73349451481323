import React from "react";

import { DropdownButton, PrimaryButton, SecondaryButton } from "../";
import { useAppSelector } from "../../redux/hooks";
import { disableAddPlayer, joinClassNames } from "../../shared/Functions";
import {
  ButtonDetails,
  ButtonDetailsWithDropdown,
} from "../../types/sharedTypes";

type TableButtonProps = {
  component: string;
  buttonDetails: ButtonDetails | ButtonDetailsWithDropdown;
  disableButton?: boolean;
};

export const TableButton: React.FC<TableButtonProps> = ({
  component,
  buttonDetails,
  disableButton,
}: TableButtonProps): JSX.Element => {
  const orgType = useAppSelector((state) => state.org.type);

  return (
    <div data-testid={`${component}-button-container`}>
      {buttonDetails.type === "secondary" ? (
        <SecondaryButton
          className="ml-4 flex justify-center items-center"
          data-testid={buttonDetails.dataTestid}
          onClick={buttonDetails.action}
          disabled={disableButton}
        >
          {buttonDetails.text}
          {buttonDetails.Icon && (
            <buttonDetails.Icon className="ml-2 h-5 w-5" />
          )}
        </SecondaryButton>
      ) : buttonDetails.type === "dropdown" ? (
        <DropdownButton
          buttonDetails={buttonDetails}
          disableButton={disableButton}
        />
      ) : (
        <PrimaryButton
          className={joinClassNames(
            component === "players" && disableAddPlayer(orgType)
              ? "hidden"
              : "block",
            "disabled:opacity-50 disabled:cursor-not-allowed",
          )}
          data-testid={buttonDetails.dataTestid}
          onClick={buttonDetails.action}
          disabled={disableButton}
        >
          {buttonDetails.text}
        </PrimaryButton>
      )}
    </div>
  );
};
