import React from "react";

import apiDocsIcon from "../../../assets/apiDocsIcon.png";
import definitionsIcon from "../../../assets/rebootDefinitionsIcon.png";
import s3BucketIcon from "../../../assets/s3BucketIcon.png";

export const DeveloperResources: React.FC = (): JSX.Element => {
  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div
        className="max-w-8xl mx-auto flex flex-col pb-2 mt-4"
        data-testid="developer-resources-container"
      >
        <h2
          className="max-w-7xl mt-4 px-4 text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-4"
          data-testid="developer-resources-header"
        >
          Developer Resources
        </h2>
        <div
          className="max-w-8xl pt-8 px-8 flex flex-wrap"
          data-testid="developer-resources-links"
        >
          <a
            href="https://docs.rebootmotion.com/"
            target="_blank"
            className="flex justify-center items-center border border-gray-200 rounded-lg shadow py-2 px-6 h-[88px] w-96 mr-[14px] mb-[14px] hover:bg-gray-50"
            data-testid="api-docs-link"
            rel="noreferrer"
          >
            <img src={apiDocsIcon} alt="api-icon" />
            <span className="text-gray-500 text-base pl-4 font-bold">
              API Documentation
            </span>
          </a>
          <a
            href="https://docs.rebootmotion.com/definitions"
            target="_blank"
            className="flex justify-center items-center border border-gray-200 rounded-lg shadow py-2 px-6 h-[88px] w-96 mr-[14px] mb-[14px] hover:bg-gray-50"
            data-testid="definitions-link"
            rel="noreferrer"
          >
            <img src={definitionsIcon} alt="document-icon" />
            <span className="text-gray-500 text-base pl-4 font-bold">
              Reboot Motion Definitions
            </span>
          </a>
          <a
            href="https://docs.rebootmotion.com/s3docs"
            target="_blank"
            className="flex justify-center items-center border border-gray-200 rounded-lg shadow py-2 px-6 h-[88px] w-96 mr-[14px] mb-[14px] hover:bg-gray-50"
            data-testid="s3-docs-link"
            rel="noreferrer"
          >
            <img src={s3BucketIcon} alt="bucket-icon" />
            <span className="text-gray-500 text-base pl-4 font-bold">
              S3 Bucket Documentation
            </span>
          </a>
        </div>
      </div>
    </main>
  );
};
