import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import React from "react";

import { MobileProcessingSessions } from "..";
import { MobileSkeleton } from "../../components";

type MobileHomeDashboardProps = {
  fetchingPending: boolean;
  inProgressSessionList: any[];
  openModal: () => void;
  fetchingPlayers: boolean;
};

export const MobileHomeDashboard: React.FC<MobileHomeDashboardProps> = ({
  fetchingPending,
  inProgressSessionList,
  openModal,
  fetchingPlayers,
}: MobileHomeDashboardProps): JSX.Element => {
  return (
    <div
      className="mt-8 lg:hidden"
      data-testid="mobile-home-dashboard-container"
    >
      <h2
        className="pl-4 text-2xl leading-6 font-bold text-gray-900 sm:pl-8"
        data-testid="mobile-sessions-processing-header"
      >
        Sessions Processing
      </h2>
      <div className="mt-8" data-testid="mobile-sessions-processing-container">
        {fetchingPending ? (
          <MobileSkeleton />
        ) : (
          <MobileProcessingSessions
            processingSessions={inProgressSessionList}
          />
        )}
      </div>
      <footer
        className="bg-gray-50 border-t-2 border-gray-200 w-full h-20 fixed inset-x-0 bottom-0"
        data-testid="mobile-footer"
      >
        <div className="flex flex-col items-center justify-center">
          <button
            className="border rounded-full bg-white border-gray-300 text-secondary-800 h-14 w-14 flex justify-center items-center relative top-[-25px] disabled:cursor-not-allowed active:bg-secondary-700 active:border-secondary-700 active:text-white"
            onClick={() => openModal()}
            data-testid="mobile-upload-button"
            disabled={fetchingPlayers}
          >
            <ArrowUpTrayIcon className="flex-shrink-0 h-8 w-8" />
          </button>
          <p
            className="text-center text-sm justify-center relative top-[-15px]"
            data-testid="upload-button-text"
          >
            Upload Data
          </p>
        </div>
      </footer>
    </div>
  );
};
