import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ApiKeys, DeveloperResources } from "..";
import { TabContent, TabItem } from "../../components";
import { TabRouteParams } from "../../types/sharedTypes";

export const Develop: React.FC = (): JSX.Element => {
  const defaultActiveTab = "develop";
  const { activeTab } = useParams<TabRouteParams>();
  const history = useHistory();

  useEffect(() => {
    if (!activeTab) {
      history.push(`/${defaultActiveTab}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      if (tab === "develop") {
        history.push(`/${tab}`);
      } else {
        history.push(`/develop/${tab}`);
      }
    }
  };

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      <div className="mt-8">
        <div className="max-w-8xl mx-auto w-full flex justify-between items-center pb-2">
          <div className="w-full flex flex-col justify-start px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mt-4 mb-8" data-testid="develop-header">
              <h2 className="text-3xl leading-6 font-bold text-gray-900">
                Develop
              </h2>
            </div>
            <div
              className="w-full border-b border-gray-200"
              data-testid="develop-tabs-container"
            >
              <ul
                className="-mb-px flex space-x-8"
                data-testid="develop-page-tabs"
              >
                <TabItem
                  page="develop"
                  title="API Keys"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
                <TabItem
                  page="resources"
                  title="Developer Resources"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
              </ul>
            </div>
          </div>
        </div>

        <div data-testid="develop-page-contents">
          <TabContent
            page="develop"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <ApiKeys />
          </TabContent>
          <TabContent
            page="resources"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <DeveloperResources />
          </TabContent>
        </div>
      </div>
    </main>
  );
};
