import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";

type GlobalFilterProps = {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export const SearchBar: React.FC<GlobalFilterProps> = ({
  globalFilter,
  setGlobalFilter,
}: GlobalFilterProps): JSX.Element => {
  return (
    <div
      className="flex flex-col justify-center items-center static w-48 ml-8"
      data-testid="search-bar-container"
    >
      <div className="w-full h-[38px] flex border border-[#DBDEE2] rounded-md">
        <label
          htmlFor="search-field"
          className="sr-only"
          id="screen-reader-search-label"
        >
          Search
        </label>
        <div className="relative w-full text-gray-400 focus-within:text-gray-700">
          <div
            className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-2"
            aria-hidden="true"
          >
            <MagnifyingGlassIcon
              className="h-5 w-5 stroke-2 text-gray-500"
              aria-hidden="true"
              id="search-icon"
              data-testid="search-icon"
            />
          </div>
          <DebouncedInput
            id="search-field"
            name="search_field"
            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-400 bg-white rounded-lg focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
            placeholder="Search..."
            type="search"
            data-testid="search-input"
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
      </div>
    </div>
  );
};
