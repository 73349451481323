import { format, parse } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  ColumnOneTableCellSkeleton,
  SecondaryButton,
  Table,
  TableCellSkeleton,
} from "../../../components";
import { columnHelper } from "../../../components/shared/SharedSessionColumns";
import { useAppContext } from "../../../context";
import { PlayerForm } from "../../../features/Player/PlayerForm";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { playerSlice } from "../../../redux/slices/playerSlice";
import {
  useGetAllPlayersQuery,
  useGetPlayerByIdQuery,
} from "../../../services";
import {
  emptyPlayerFormValues,
  playerListLimit,
} from "../../../shared/Constants";
import {
  disableEditPlayer,
  joinClassNames,
  showPlayerHandedness,
} from "../../../shared/Functions";
import { PlayerFormValues } from "../../../types";
import { PlayersTabProps } from "../Players";

export const Roster: React.FC<PlayersTabProps> = ({
  offsetForPlayerList,
  setOffsetForPlayerList,
  playerQueryCount,
  setPlayerQueryCount,
  combinedPlayers,
  setCombinedPlayers,
  numberOfQueriesForPlayers,
}: PlayersTabProps): JSX.Element => {
  // State and AppContext
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("playersPageSize") || "10",
  );
  const {
    setModalSingleStep,
    setModalState,
    setModalTitle,
    setDisplayBack,
    playerIdToRetrieve,
    setPlayerIdToRetrieve,
  } = useAppContext();

  // Redux calls
  const storedPlayerList = useAppSelector((state) => state.players.playerList);
  const playerCount = useAppSelector((state) => state.org).players.total;
  const orgType = useAppSelector((state) => state.org).type;

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayers } = playerSlice.actions;

  // RTK Queries
  const { data: singlePlayer } = useGetPlayerByIdQuery(playerIdToRetrieve, {
    skip: !playerIdToRetrieve,
  });
  const {
    data: playerList,
    originalArgs,
    isFetching: fetchingPlayers,
  } = useGetAllPlayersQuery(
    {
      offset: offsetForPlayerList,
      limit: playerListLimit,
    },
    {
      skip:
        storedPlayerList.length === playerCount ||
        playerQueryCount === numberOfQueriesForPlayers ||
        playerIdToRetrieve !== "",
    },
  );

  useEffect(() => {
    if (playerList && originalArgs) {
      if (playerList.length === playerListLimit) {
        setOffsetForPlayerList(originalArgs.offset + playerListLimit);
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setPlayerQueryCount(playerQueryCount + 1);
      } else if (playerList.length < playerListLimit) {
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setOffsetForPlayerList(0);
        setPlayerQueryCount(playerQueryCount + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerList]);

  useEffect(() => {
    if (storedPlayerList.length !== playerCount && combinedPlayers) {
      dispatch(storePlayers(combinedPlayers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedPlayers]);

  useEffect(() => {
    if (singlePlayer) {
      const updatedPlayerList = storedPlayerList
        .filter((player) => player.orgPlayerId !== playerIdToRetrieve)
        .concat(singlePlayer);
      dispatch(storePlayers(updatedPlayerList));
      setPlayerIdToRetrieve("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePlayer]);

  const playersSortedByLastName = [...storedPlayerList].sort((a, b) =>
    a.lastName < b.lastName ? -1 : 1,
  );

  const playersColumns = useMemo(
    () => [
      columnHelper.accessor("playersTableValues.playerName", {
        header: "Player",
        cell: function playerNameCell(element: any) {
          return (
            <Link
              to={`/player/${element.row.original.orgPlayerId}`}
              className="font-bold text-primary-700 hover:underline"
              data-testid="player-link"
            >
              {`${element.row.original.lastName}, ${element.row.original.firstName}`}
            </Link>
          );
        },
      }),
      columnHelper.accessor("throws", {
        header: "Throws",
        cell: function throwsCell(element: any) {
          return (
            <div
              className={joinClassNames(
                !element.getValue() ? "text-red-500 italic" : "text-gray-900",
                "font-medium",
              )}
            >
              {element.getValue() ? element.getValue() : "Unknown"}
            </div>
          );
        },
      }),
      columnHelper.accessor("hits", {
        header: "Hits",
        cell: function hitsCell(element: any) {
          return (
            <div
              className={joinClassNames(
                !element.getValue() ? "text-red-500 italic" : "text-gray-900",
                "font-medium",
              )}
            >
              {element.getValue() ? element.getValue() : "Unknown"}
            </div>
          );
        },
      }),
      columnHelper.accessor("playersTableValues.height", {
        header: "Height",
        enableSorting: false,
        cell: function heightCell(element: any) {
          return (
            <div
              className={joinClassNames(
                !element.getValue() ? "text-red-500 italic" : "text-gray-900",
                "font-medium",
              )}
            >
              {element.getValue() ? element.getValue() : "Unknown"}
            </div>
          );
        },
      }),
      columnHelper.accessor("playersTableValues.weight", {
        header: "Weight",
        enableSorting: false,
        cell: function weightCell(element: any) {
          return (
            <div
              className={joinClassNames(
                !element.getValue() ? "text-red-500 italic" : "text-gray-900",
                "font-medium",
              )}
            >
              {element.getValue() ? element.getValue() : "Unknown"}
            </div>
          );
        },
      }),
      columnHelper.accessor("dateOfBirth", {
        header: "Birth Date",
        enableSorting: false,
        cell: function birthDateCell(element: any) {
          return (
            <div
              className={joinClassNames(
                element.getValue() === "1/1/1900" ||
                  element.getValue() === "Unknown"
                  ? "text-red-500 italic"
                  : "text-gray-900",
                "font-medium",
              )}
            >
              {element.getValue()}
            </div>
          );
        },
      }),
      columnHelper.accessor("editPlayer", {
        header: "",
        enableSorting: false,
        cell: function editCell(element: any) {
          const playerName = `${element.cell.row.original.firstName} ${element.cell.row.original.lastName}`;
          const orgPlayerId = element.cell.row.original.orgPlayerId;
          const playerBirthDate =
            element.row.original.dateOfBirth !== "Unknown"
              ? format(
                  parse(
                    element.row.original.dateOfBirth,
                    "M/d/yyyy",
                    new Date(),
                  ),
                  "yyyy-MM-dd",
                )
              : "1900-01-01";
          return (
            <div>
              <SecondaryButton
                className="mx-14"
                onClick={() => {
                  const playerForm: PlayerFormValues = {
                    firstName: element.row.original.firstName,
                    middleName: element.row.original.middleName,
                    lastName: element.row.original.lastName,
                    dateOfBirth: new Date(`${playerBirthDate}T00:00-0800`),
                    throws: {
                      value: element.row.original.throws,
                      label: element.row.original.throws,
                    },
                    hits: {
                      value: element.row.original.hits,
                      label: element.row.original.hits,
                    },
                    heightFeet: element.row.original.height.feet,
                    heightInches: element.row.original.height.inches,
                    weight: element.row.original.weight,
                    officialLeagueId: element.row.original.officialLeagueId,
                    orgPlayerId: element.row.original.orgPlayerId,
                  };
                  openModal(`Edit - ${playerName}`, playerForm, orgPlayerId);
                }}
                data-testid="edit-player-button"
              >
                Edit
              </SecondaryButton>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const columnsWithoutHandOrEdit = useMemo(
    () =>
      playersColumns.filter(
        (column) =>
          column.accessorKey !== "throws" &&
          column.accessorKey !== "hits" &&
          column.accessorKey !== "editPlayer",
      ),
    [playersColumns],
  );

  const tableData = useMemo(
    () => (fetchingPlayers ? Array(10).fill({}) : playersSortedByLastName),
    [fetchingPlayers, playersSortedByLastName],
  );

  const tableColumns = useMemo(
    () =>
      fetchingPlayers
        ? (!showPlayerHandedness(orgType) && disableEditPlayer(orgType)
            ? columnsWithoutHandOrEdit
            : playersColumns
          ).map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : !showPlayerHandedness(orgType) && disableEditPlayer(orgType)
          ? columnsWithoutHandOrEdit
          : playersColumns,
    [fetchingPlayers, columnsWithoutHandOrEdit, orgType, playersColumns],
  );

  const openModal = (
    title: string,
    playerFormValues?: PlayerFormValues,
    orgPlayerId?: string,
  ) => {
    const step = (
      <PlayerForm
        formValues={playerFormValues ? playerFormValues : emptyPlayerFormValues}
        currentOrgPlayerId={orgPlayerId ? orgPlayerId : ""}
      />
    );
    setModalSingleStep(step);
    setModalTitle(title);
    setModalState(true);
  };

  const buttonAction = () => {
    openModal("Add New Player");
  };

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
    if (selectedPageSize) {
      sessionStorage.setItem("playersPageSize", selectedPageSize);
    }
  }, [setDisplayBack, selectedPageSize]);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div className="block min-w-max" data-testid="players-table-container">
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="players-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={fetchingPlayers}
          customRowClassName="players-table-row"
          component="players"
          buttons={[
            {
              type: "primary",
              action: buttonAction,
              text: "Add Player",
              dataTestid: "add-player-button",
            },
          ]}
        />
      </div>
    </main>
  );
};
