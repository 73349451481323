import { PencilIcon } from "@heroicons/react/24/outline";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";

import { ComparisonSegment, PopulationSegment, PrimarySegment } from "..";
import {
  BlankButton,
  CustomDropdownWithChevron,
  ErrorToast,
  PrimaryButton,
  SuccessToast,
} from "../../components";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { requestedAnalysisFormSlice } from "../../redux/slices/requestedAnalysisFormSlice";
import { requestedAnalysisSlice } from "../../redux/slices/requestedAnalysisSlice";

import { useCreateRequestedAnalysisMutation } from "../../services";
import { customSelectStyles } from "../../shared/Constants";
import { joinClassNames } from "../../shared/Functions";
import { RequestedAnalysisFormValues } from "../../types";

export const movementTypeOptions = [
  { value: "hitting", label: "Swing", id: 1 },
  { value: "pitching", label: "Throw", id: 2 },
];

export const handOptions = [
  { value: "righty", label: "Right Hand", id: "RHA" },
  { value: "lefty", label: "Left Hand", id: "LHA" },
];

export const RequestAnalysisForm: React.FC = (): JSX.Element => {
  const { push, goBack } = useHistory();

  // State and AppContext
  const [shouldShowSegments, setShouldShowSegments] = useState(false);
  const { setDisplayBack, setDialogState } = useAppContext();
  const [primarySegmentDefined, setPrimarySegmentDefined] = useState(false);
  const [populationSegmentDefined, setPopulationSegmentDefined] =
    useState(false);
  const [comparisonSegmentDefined, setComparisonSegmentDefined] =
    useState(false);
  const [editPrimarySegment, setEditPrimarySegment] = useState(false);
  const [editPopulationSegment, setEditPopulationSegment] = useState(false);
  const [editComparisonSegment, setEditComparisonSegment] = useState(false);
  const [disableDropdowns, setDisableDropdowns] = useState(false);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeRequestedAnalysisFormState, clearRequestedAnalysisFormState } =
    requestedAnalysisFormSlice.actions;
  const { clearRequestedAnalysisState } = requestedAnalysisSlice.actions;

  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );
  const requestedAnalysisState = useAppSelector(
    (state) => state.requestedAnalysis,
  );
  const selectedMovementType = requestedAnalysisFormState.movementType.value;

  // RTK Mutation
  const [createRequestedAnalysis] = useCreateRequestedAnalysisMutation();

  const { primarySegmentData, populationSegmentData, comparisonSegmentData } =
    requestedAnalysisState;

  const errorToast = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      id: "createAnalysisError",
    });

  const successToast = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="mt-modal" />);

  const formRef = useRef<FormikProps<RequestedAnalysisFormValues>>(null);

  const formValues = {
    name: "",
    mocapType: { value: "", label: "", id: 0 },
    hand: { value: "", label: "", id: "" },
    movementType: { value: "", label: "", id: 0 },
    primarySegment: {
      dateRange: [],
      individualDates: [],
      dateCriteria: { value: "", label: "" },
      criteria: [],
      id: 0,
    },
    populationSegment: {
      dateRange: [],
      individualDates: [],
      dateCriteria: { value: "", label: "" },
      criteria: [],
      id: 0,
    },
    comparisonSegment: {
      dateRange: [],
      individualDates: [],
      dateCriteria: { value: "", label: "" },
      criteria: [],
      id: 0,
    },
  };

  const handleRequestAnalysis = () => {
    const requestBody: {
      name: string;
      primary_analysis_segment_id: number | null;
      status: string;
      population_analysis_segment_id?: number | null;
      comparison_analysis_segment_id?: number | null;
    } = {
      name: requestedAnalysisFormState.name,
      primary_analysis_segment_id: primarySegmentData.analysisSegmentId,
      status: "requested",
    };

    if (selectedMovementType !== "hitting") {
      if (populationSegmentData?.analysisSegmentId !== null) {
        requestBody.population_analysis_segment_id =
          populationSegmentData.analysisSegmentId;
      }

      if (comparisonSegmentData?.analysisSegmentId !== null) {
        requestBody.comparison_analysis_segment_id =
          comparisonSegmentData.analysisSegmentId;
      }
    }

    createRequestedAnalysis(requestBody)
      .unwrap()
      .then(() => {
        dispatch(clearRequestedAnalysisState());
        dispatch(clearRequestedAnalysisFormState());
        push("/analyze");
        successToast("Your analysis has been requested.");
      })
      .catch(() => {
        errorToast(
          "There was an error requesting your analysis. Please try again.",
        );
      });
  };

  const cancelAnalysisRequest = () => {
    goBack();
    dispatch(clearRequestedAnalysisFormState());
    dispatch(clearRequestedAnalysisState());
  };

  useEffect(() => {
    setDisplayBack && setDisplayBack(true);
  }, [setDisplayBack]);

  useEffect(() => {
    if (
      requestedAnalysisFormState.hand &&
      requestedAnalysisFormState.hand.value !== "" &&
      requestedAnalysisFormState.movementType &&
      requestedAnalysisFormState.movementType.value !== ""
    ) {
      setShouldShowSegments(true);
    } else {
      setShouldShowSegments(false);
    }
  }, [requestedAnalysisFormState]);

  useEffect(() => {
    if (
      primarySegmentDefined ||
      populationSegmentDefined ||
      comparisonSegmentDefined
    ) {
      setDisableDropdowns(true);
    } else {
      setDisableDropdowns(false);
    }
  }, [
    primarySegmentDefined,
    populationSegmentDefined,
    comparisonSegmentDefined,
  ]);

  return (
    <main className="relative overflow-y-auto w-full z-0">
      <div className="pb-28 w-full">
        <div className="mt-8">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={formValues}
            validationSchema={Yup.object({
              hand: Yup.object().shape({
                value: Yup.string(),
                label: Yup.string(),
                id: Yup.string(),
              }),
              movementType: Yup.object().shape({
                value: Yup.string(),
                label: Yup.string(),
                id: Yup.number(),
              }),
              primarySegment: Yup.object().shape({
                dateRange: Yup.array().of(Yup.string()),
                dateCriteria: Yup.object().shape({
                  value: Yup.string(),
                  label: Yup.string(),
                }),
                criteria: Yup.array()
                  .min(1)
                  .of(
                    Yup.object().shape({
                      field: Yup.object().shape({
                        value: Yup.string() && Yup.string().required(),
                        label: Yup.string() && Yup.string().required(),
                      }),
                      comparison: Yup.object().shape({
                        value: Yup.string() && Yup.string().required(),
                        label: Yup.string() && Yup.string().required(),
                      }),
                      option: Yup.lazy((value) =>
                        Array.isArray(value)
                          ? Yup.array()
                              .min(1)
                              .of(
                                Yup.object().shape({
                                  value:
                                    Yup.string() && Yup.string().required(),
                                  label:
                                    Yup.string() && Yup.string().required(),
                                }),
                              )
                          : Yup.object().shape({
                              value: Yup.string() && Yup.string().required(),
                              label: Yup.string() && Yup.string().required(),
                            }),
                      ),
                    }),
                  ),
              }),
            })}
            onSubmit={() => {
              false;
            }}
          >
            {({
              values,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              isValid,
              dirty,
            }) => (
              <div>
                <form
                  className="px-4"
                  data-testid="requested-analysis-form"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  data-lpignore="true"
                >
                  <div
                    className="max-w-8xl mx-auto flex justify-between items-center pb-2"
                    data-testid="data-source-header"
                  >
                    <div className="flex justify-start items-center text-gray-900">
                      <h2
                        className={joinClassNames(
                          !requestedAnalysisFormState.name ? "opacity-20" : "",
                          "mt-4 px-4 text-3xl leading-6 font-bold sm:px-6 lg:px-8 lg:pb-6",
                        )}
                        data-testid="requested-analysis-name"
                      >
                        {requestedAnalysisFormState.name
                          ? requestedAnalysisFormState.name
                          : "Name Analysis..."}
                      </h2>
                      <button
                        data-testid="edit-analysis-name-button"
                        onClick={(event) => {
                          event.preventDefault();
                          setDialogState({
                            shouldOpen: true,
                            type: "analysis",
                          });
                        }}
                      >
                        <PencilIcon className="w-5 ml-4 mb-2" />
                      </button>
                    </div>
                  </div>

                  <div
                    className="max-w-3xl px-4 ml-4 flex justify-start pb-2"
                    data-testid="required-requested-analysis-params"
                  >
                    <div
                      className="w-60 mr-4"
                      data-testid="hand-dropdown-container"
                    >
                      <Select
                        name="hand-dropdown"
                        aria-labelledby="hand-aria-label"
                        inputId="hand-dropdown"
                        options={handOptions}
                        value={
                          requestedAnalysisFormState.hand &&
                          requestedAnalysisFormState.hand.value !== ""
                            ? requestedAnalysisFormState.hand
                            : null
                        }
                        placeholder="Select Hand"
                        isSearchable={false}
                        onChange={(hand) => {
                          if (hand) {
                            setFieldValue("hand", hand);
                            dispatch(
                              storeRequestedAnalysisFormState({
                                ...requestedAnalysisFormState,
                                hand: hand,
                              }),
                            );
                          }
                        }}
                        styles={customSelectStyles}
                        components={{
                          DropdownIndicator: CustomDropdownWithChevron,
                          IndicatorSeparator: () => null,
                        }}
                        onBlur={() => {
                          setFieldTouched("hand", true);
                        }}
                        isDisabled={disableDropdowns}
                      />
                    </div>
                    <div
                      className="w-60"
                      data-testid="movement-type-dropdown-container"
                    >
                      <Select
                        name="movement-type-dropdown"
                        aria-labelledby="movement-type-aria-label"
                        inputId="movement-type-dropdown"
                        options={movementTypeOptions}
                        value={
                          requestedAnalysisFormState.movementType &&
                          requestedAnalysisFormState.movementType.value !== ""
                            ? requestedAnalysisFormState.movementType
                            : null
                        }
                        placeholder="Select Movement Type"
                        isSearchable={false}
                        onChange={(movementType) => {
                          if (movementType) {
                            setFieldValue("movementType", movementType);
                            dispatch(
                              storeRequestedAnalysisFormState({
                                ...requestedAnalysisFormState,
                                movementType: movementType,
                              }),
                            );
                          }
                        }}
                        styles={customSelectStyles}
                        components={{
                          DropdownIndicator: CustomDropdownWithChevron,
                          IndicatorSeparator: () => null,
                        }}
                        onBlur={() => {
                          setFieldTouched("movementType", true);
                        }}
                        isDisabled={disableDropdowns}
                      />
                    </div>
                  </div>

                  <div
                    className="max-w-8xl pt-6 mx-8"
                    data-testid="player-group-segments-container"
                  >
                    <PrimarySegment
                      shouldShowSegments={shouldShowSegments}
                      primarySegmentDefined={primarySegmentDefined}
                      setPrimarySegmentDefined={setPrimarySegmentDefined}
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      editPrimarySegment={editPrimarySegment}
                      setEditPrimarySegment={setEditPrimarySegment}
                      isValid={isValid}
                      dirty={dirty}
                    />
                    {selectedMovementType !== "hitting" && (
                      <>
                        <PopulationSegment
                          shouldShowSegments={shouldShowSegments}
                          populationSegmentDefined={populationSegmentDefined}
                          setPopulationSegmentDefined={
                            setPopulationSegmentDefined
                          }
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          editPopulationSegment={editPopulationSegment}
                          setEditPopulationSegment={setEditPopulationSegment}
                          isValid={isValid}
                          dirty={dirty}
                        />
                        <ComparisonSegment
                          shouldShowSegments={shouldShowSegments}
                          comparisonSegmentDefined={comparisonSegmentDefined}
                          setComparisonSegmentDefined={
                            setComparisonSegmentDefined
                          }
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          editComparisonSegment={editComparisonSegment}
                          setEditComparisonSegment={setEditComparisonSegment}
                          isValid={isValid}
                          dirty={dirty}
                        />
                      </>
                    )}
                  </div>
                </form>
                <footer
                  className="h-20 bg-gray-50 flex justify-between items-center px-10 py-7 fixed bottom-0 right-0 left-24"
                  data-testid="review-request-footer"
                >
                  <BlankButton
                    data-testid="request-analysis-cancel-button"
                    onClick={cancelAnalysisRequest}
                  >
                    Cancel
                  </BlankButton>
                  <PrimaryButton
                    className="disabled:bg-[#E36006] disabled:text-white disabled:opacity-50"
                    data-testid="request-analysis-button"
                    disabled={!primarySegmentDefined}
                    onClick={handleRequestAnalysis}
                  >
                    Request Analysis
                  </PrimaryButton>
                </footer>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};
