/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  CalendarIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { components } from "react-select";

export const CustomDropdownWithCalendar = (props: any): JSX.Element => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <CalendarIcon
          className="h-5 text-gray-700 stroke-2"
          aria-hidden="true"
        />
      </components.DropdownIndicator>
    )
  );
};

export const CustomDropdownWithChevron = (props: any): JSX.Element => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon
          className="h-5 text-gray-700 stroke-2"
          aria-hidden="true"
        />
      </components.DropdownIndicator>
    )
  );
};

export const CustomOptionWithCheckbox = (props: any): JSX.Element => {
  return (
    <div data-testid="checkbox-option-container">
      <components.Option {...props}>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={props.isSelected}
            name="custom-checkbox"
            id="custom-checkbox"
            onChange={() => null}
            className="mr-4  ml-3 rounded border border-gray-300 checked:bg-[#FB6A07] checked:hover:bg-[#FB6A07]"
          />
          <label>
            <span className="flex flex-col text-sm">
              <span>{props.label}</span>
              <span className="mt-[2px] text-gray-400">{`DOB: ${props.data.dob}`}</span>
            </span>
          </label>
        </div>
      </components.Option>
    </div>
  );
};

export const CustomMenuWithSearch = ({
  selectProps,
  ...props
}: any): JSX.Element => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;
  return (
    <div data-testid="custom-menu">
      <p className="px-4 pt-4 pb-3 text-xs">Players</p>
      <div className="bg-[#F9FAFB] h-[58px] mb-2 flex justify-center items-center">
        <div className="flex items-center border border-gray-300 rounded-md bg-white w-11/12">
          <MagnifyingGlassIcon className="h-5 w-5 ml-2 text-black" />
          <input
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            name="custom-search-input"
            id="custom-search-input"
            value={inputValue}
            onChange={(e) =>
              onInputChange(e.currentTarget.value, {
                action: "input-change",
              })
            }
            onMouseDown={(e) => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
            onFocus={onMenuInputFocus}
            placeholder="Search"
            className="border-none rounded-md w-3/4 placeholder:text-gray-500"
          />
        </div>
      </div>
      <components.MenuList {...props} selectProps={selectProps} />
    </div>
  );
};
