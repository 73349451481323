import { Disclosure } from "@headlessui/react";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";

import { CustomTooltip, Segment, SegmentDetails } from "../..";
import { useAppSelector } from "../../../redux/hooks";
import { joinClassNames } from "../../../shared/Functions";
import { RequestedAnalysisFormValues } from "../../../types";

type ComparisonSegmentProps = {
  shouldShowSegments: boolean;
  comparisonSegmentDefined: boolean;
  setComparisonSegmentDefined: React.Dispatch<React.SetStateAction<boolean>>;
  values: RequestedAnalysisFormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  editComparisonSegment: boolean;
  setEditComparisonSegment: React.Dispatch<React.SetStateAction<boolean>>;
  isValid: boolean;
  dirty: boolean;
};

export const ComparisonSegment: React.FC<ComparisonSegmentProps> = ({
  shouldShowSegments,
  comparisonSegmentDefined,
  setComparisonSegmentDefined,
  values,
  setFieldValue,
  setFieldTouched,
  editComparisonSegment,
  setEditComparisonSegment,
  isValid,
  dirty,
}: ComparisonSegmentProps): JSX.Element => {
  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );
  const requestedAnalysisState = useAppSelector(
    (state) => state.requestedAnalysis,
  );

  const { comparisonSegmentData } = requestedAnalysisState;

  return (
    <div
      className={joinClassNames(
        !shouldShowSegments ? "opacity-50 pointer-events-none" : "",
        "block min-w-max mt-8 border border-gray-200 rounded-lg shadow",
      )}
      data-testid="comparison-segment-container"
    >
      <Disclosure
        defaultOpen={
          requestedAnalysisFormState.comparisonSegment.criteria.length > 0
            ? true
            : false
        }
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={joinClassNames(
                open ? "rounded-t-md" : "rounded-md",
                "p-4 flex bg-gray-50 w-full items-center justify-between",
              )}
              data-testid="define-comparison-segment-header"
            >
              <div className="flex">
                <PlusIcon className="w-6 text-secondary-500 mr-4" />
                <p className="text-gray-900 text-2xl">
                  <span
                    className={joinClassNames(
                      comparisonSegmentDefined ? "hidden" : "",
                    )}
                  >
                    Define
                  </span>{" "}
                  Comparison Segment{" "}
                  <span className="text-gray-300">(optional)</span>
                </p>
                <InformationCircleIcon
                  className="w-6 text-gray-500 ml-5"
                  id="tooltip-anchor-comparison"
                  data-tooltip-id="tooltip-anchor-comparison"
                />
                <CustomTooltip
                  anchorId={"tooltip-anchor-comparison"}
                  type="comparison"
                  info="Define the Comparison Segment to use as a reference or benchmark when comparing other segments."
                  place={"right"}
                />
              </div>
              {comparisonSegmentDefined && (
                <div
                  className="flex space-x-6"
                  data-testid="comparison-segment-numbers-container"
                >
                  <p
                    className="text-2xl"
                    data-testid="comparison-segment-player-count"
                  >
                    <span className="text-secondary-900">
                      {comparisonSegmentData.numPlayers}
                    </span>{" "}
                    <span className="text-gray-900">Players</span>
                  </p>
                  <p
                    className="text-2xl"
                    data-testid="comparison-segment-movement-count"
                  >
                    <span className="text-secondary-900">
                      {comparisonSegmentData.numMovements}
                    </span>{" "}
                    <span className="text-gray-900">Movements</span>
                  </p>
                </div>
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="rounded-b-md border-t border-t-gray-200">
              {!comparisonSegmentDefined ? (
                <Segment
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  segmentType={"comparison"}
                  movementTypeId={requestedAnalysisFormState.movementType.id}
                  setComparisonSegmentDefined={setComparisonSegmentDefined}
                  editComparisonSegment={editComparisonSegment}
                  setEditComparisonSegment={setEditComparisonSegment}
                  isValid={isValid}
                  dirty={dirty}
                />
              ) : (
                <SegmentDetails
                  segmentType={"comparison"}
                  setComparisonSegmentDefined={setComparisonSegmentDefined}
                  setEditComparisonSegment={setEditComparisonSegment}
                />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
