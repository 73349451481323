import { createSlice } from "@reduxjs/toolkit";

import { Sessions } from "../../types/index";

const initialState: Sessions = {
  sessionList: [],
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    storeSessions: (state, action) => {
      state.sessionList = action.payload;
    },
  },
});
