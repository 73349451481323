import { camelCaseResponse } from "../shared/Functions";
import { emptyApi } from "./emptyApi";

export const dataExportExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    exportData: builder.mutation({
      query: (data) => ({
        url: "/data_export",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const { useExportDataMutation } = dataExportExtendedApi;
