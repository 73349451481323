import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  UploadFileStatusEnum,
  UploadProgress,
  UploadSession,
} from "../../types";
import { Uploads } from "../../types/uploadSessionTypes";

export const initialState: Uploads = {
  uploads: [],
};

export const uploadSessionSlice = createSlice({
  name: "uploadSession",
  initialState,
  reducers: {
    storeUploadSessionState: (state, action: PayloadAction<UploadSession>) => {
      state.uploads.push(action.payload);
    },
    updateUploadSessionState: (state, action) => {
      const uploadToUpdate = state.uploads.find(
        (upload) => upload.uploadSessionId === action.payload.uploadSessionId,
      );
      if (uploadToUpdate) {
        if (action.payload.error === "Upload Failed") {
          uploadToUpdate.uploadedFiles = uploadToUpdate.uploadedFiles.map(
            (file) => {
              if (file.fileName === action.payload.fileName) {
                file.uploadStatus = UploadFileStatusEnum.Error;
                return file;
              }
              return file;
            },
          );
        } else if (action.payload.error === "Retry Upload") {
          uploadToUpdate.uploadedFiles = uploadToUpdate.uploadedFiles.map(
            (file) => {
              if (file.fileName === action.payload.fileName) {
                file.uploadStatus = UploadFileStatusEnum.Pending;
                return file;
              }
              return file;
            },
          );
        } else if (action.payload.error === "Upload Canceled") {
          uploadToUpdate.uploadedFiles = uploadToUpdate.uploadedFiles.map(
            (file) => {
              if (file.fileName === action.payload.fileName) {
                file.uploadStatus = UploadFileStatusEnum.Canceled;
                return file;
              }
              return file;
            },
          );
        } else {
          uploadToUpdate.uploadedFiles = uploadToUpdate.uploadedFiles.map(
            (file) => {
              if (file.fileName === action.payload.fileName) {
                file.uploadStatus = UploadFileStatusEnum.Complete;
                return file;
              }
              return file;
            },
          );
        }
      }
    },
    updateUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      const uploadToUpdate = state.uploads.find(
        (upload) => upload.uploadSessionId === action.payload.uploadSessionId,
      );
      if (uploadToUpdate) {
        uploadToUpdate.uploadedFiles = uploadToUpdate.uploadedFiles.map(
          (file) => {
            if (file.fileName === action.payload.fileName) {
              file.uploadProgress = action.payload.uploadProgress;
              return file;
            }
            return file;
          },
        );
      }
    },
    clearUploadSessionState: (state) => {
      state = initialState;
      return state;
    },
  },
});
