import * as FullStory from "@fullstory/browser";
import { Store } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { App } from "./App";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./shared/Auth0Utility"; // custom auth0 provider
import "./styles/index.css";

// Extend the Window interface in the global namespace
// Taken from https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare global {
  interface Window {
    store: Store;
    Cypress: any;
  }
}

// If we are running Cypress tests, set the store equal to the window.store so it's accessible
if (window.Cypress) {
  window.store = store;
}

Sentry.init({
  dsn: "https://33a062c936f64b2d9ecaaf625a277e4c@o867858.ingest.sentry.io/5823920",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: process.env.NODE_ENV === "test" ? 1.0 : 0.6,
  enabled: process.env.NODE_ENV !== "development",
});

if (
  process.env.REACT_APP_RUN_ENV === "env-prod" ||
  process.env.REACT_APP_RUN_ENV === "env-stage"
) {
  FullStory.init({ orgId: "14V0T5" });
}

const persistor = persistStore(store);

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const apiIdentifier = process.env.REACT_APP_AUTH0_AUDIENCE || "";

root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: apiIdentifier,
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary>
          <Router>
            <Toaster containerStyle={{ zIndex: 999999 }} />
            <Switch>
              <Route path={"/"} component={App} />
            </Switch>
          </Router>
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
