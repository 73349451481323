import {
  ArrowUpTrayIcon,
  ExclamationCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "react-circular-progressbar/dist/styles.css";
import ReactModal from "react-modal";

import { ReactComponent as StepCircleComplete } from "../../../assets/StepperAssets/StepCircleComplete.svg";
import { ReactComponent as XIcon } from "../../../assets/xIcon.svg";
import {
  GradientProgressSpinner,
  ModalHeader,
  PrimaryButton,
} from "../../../components";
import { useAppContext } from "../../../context";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { uploadSessionSlice } from "../../../redux/slices/uploadSessionSlice";
import { usePatchSessionUploadMutation } from "../../../services/session";
import { joinClassNames } from "../../../shared/Functions";
import { mobilePopupStyles, popupStyles } from "../../../styles/modalStyles";
import { PopupProps } from "../../../types/sharedTypes";
import {
  cancelFileUpload,
  triggerNewS3Upload,
} from "../services/S3UploadFunctions";

export const FileUploadProgress: React.FC<PopupProps> = ({ title }) => {
  // State and AppContext
  const [minimizePopup, setMinimizePopup] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const {
    setPopupState,
    popupState,
    setPopupTitle,
    uploadFiles,
    abortControllers,
    setAbortControllers,
    setUploadInProgress,
    uploadInProgress,
  } = useAppContext();
  const [canceledFilesCount, setCanceledFilesCount] = useState(0);

  // Redux calls
  const dispatch = useAppDispatch();
  const { clearUploadSessionState, updateUploadProgress } =
    uploadSessionSlice.actions;
  const uploadSessionState = useAppSelector(
    (state) => state.uploadSession.uploads,
  );

  // RTK Mutation
  const [patchSessionUpload] = usePatchSessionUploadMutation();

  const toggleMinimize = () => {
    setMinimizePopup(!minimizePopup);
    setShowContent(!showContent);
  };

  const closeButton = document.getElementById(
    "file-upload-popup-modal-close-button",
  );

  if (isMobile && uploadInProgress) {
    closeButton?.classList.add("hidden");
  } else {
    closeButton?.classList.remove("hidden");
  }

  const closePopup = () => {
    if (uploadInProgress) {
      window.alert(
        "An upload is still in progress. Please wait for the upload to complete before closing.",
      );
    } else {
      setPopupTitle("");
      setCanceledFilesCount(0);
      setPopupState(false);
      setUploadInProgress(false);
      dispatch(clearUploadSessionState());
      if (minimizePopup) {
        setMinimizePopup(false);
      }
      if (showContent) {
        setShowContent(false);
      }
      setAbortControllers([]);
    }
  };

  const cancelFile = (event: { target: any }, uploadSessionId: number) => {
    setCanceledFilesCount(canceledFilesCount + 1);
    let abortController: AbortController;
    const canceledFileId: number = parseInt(event.target.id);
    const uploadSession = uploadSessionState.find(
      (session) => session.uploadSessionId === uploadSessionId,
    );
    if (uploadSession) {
      const canceledFile = uploadSession.uploadedFiles.find(
        (file) => file.fileId === canceledFileId,
      );
      const foundController = abortControllers.find((abortInfo) => {
        return abortInfo.fileName === canceledFile?.fileName;
      });

      if (canceledFile && foundController) {
        abortController = foundController.abortController;
        cancelFileUpload(
          canceledFile,
          dispatch,
          abortController,
          patchSessionUpload,
        );
      }
    }
  };

  const retryFileUpload = (fileName: string, uploadSessionId: number) => {
    const foundControllerInfo = abortControllers.find((abortInfo) => {
      return abortInfo.fileName === fileName;
    });
    const uploadSession = uploadSessionState.find(
      (session) => session.uploadSessionId === uploadSessionId,
    );
    if (foundControllerInfo && uploadSession) {
      dispatch(
        updateUploadProgress({
          fileName,
          uploadProgress: 0,
          uploadSessionId,
        }),
      );
      triggerNewS3Upload(
        fileName,
        uploadSession,
        uploadFiles,
        dispatch,
        patchSessionUpload,
        foundControllerInfo,
      );
    }
  };

  useEffect(() => {
    if (popupState) {
      setShowContent(true);
    } else {
      sessionStorage.removeItem("popupState");
      sessionStorage.removeItem("popupTitle");
    }
  }, [popupState]);

  useEffect(() => {
    if (popupState) {
      let allFiles: any[] = [];
      if (uploadSessionState) {
        uploadSessionState.forEach((session) => {
          allFiles = [...allFiles, ...session.uploadedFiles];
        });
      }
      const numberOfFilesPendingUpload = allFiles.filter(
        (file) => file.uploadStatus === "PENDING",
      ).length;
      if (numberOfFilesPendingUpload > 0) {
        const filesSingularOrPlural =
          numberOfFilesPendingUpload === 1 ? "file" : "files";
        setPopupTitle(
          `Uploading ${numberOfFilesPendingUpload} ${filesSingularOrPlural}`,
        );
        sessionStorage.setItem(
          "popupTitle",
          `Uploading ${numberOfFilesPendingUpload} ${filesSingularOrPlural}`,
        );
      } else {
        setPopupTitle("Upload Complete");
        sessionStorage.setItem("popupTitle", "Upload Complete");
        setUploadInProgress(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSessionState, popupState]);

  return (
    <ReactModal
      isOpen={popupState}
      style={isMobile ? mobilePopupStyles : popupStyles}
    >
      <ModalHeader
        title={title}
        handleClose={closePopup}
        component="file-upload-popup"
        minimizePopup={minimizePopup}
        toggleMinimize={toggleMinimize}
      />
      {showContent && uploadSessionState.length && (
        <div
          className="h-screen sm:h-full"
          data-testid="file-upload-popup-body"
        >
          <div
            className={joinClassNames(
              isMobile ? "h-[70%]" : "",
              "h-full flex flex-col w-full overflow-scroll",
            )}
            data-testid="files-container"
          >
            {uploadSessionState.map((upload) => {
              return upload.uploadedFiles.map((file, index) => {
                return (
                  <div
                    className="flex w-full border-b border-b-gray-200 p-4 h-16 last:border-0"
                    data-testid="single-file"
                    key={index}
                  >
                    <div
                      className="flex w-2/3 justify-start"
                      data-testid="file-name-container"
                    >
                      <div className="mr-4" data-testid="upload-icon-container">
                        {(file.uploadStatus === "PENDING" && (
                          <ArrowUpTrayIcon
                            className="text-gray-500 w-7 sm:w-8"
                            data-testid="upload-icon"
                          />
                        )) ||
                          (file.uploadStatus === "CANCELED" && (
                            <ArrowUpTrayIcon
                              className="text-gray-500 w-7 sm:w-8"
                              data-testid="upload-icon"
                            />
                          )) ||
                          (file.uploadStatus === "ERROR" && (
                            <ExclamationCircleIcon
                              className="text-gray-500 w-7 sm:w-8"
                              data-testid="exclamation-icon"
                            />
                          )) ||
                          (file.uploadStatus === "COMPLETE" && (
                            <VideoCameraIcon
                              className="text-gray-500 w-7 sm:w-8"
                              data-testid="video-icon"
                            />
                          ))}
                      </div>
                      <div className="w-full flex flex-col text-xs">
                        <p
                          className="text-gray-500 font-bold truncate w-5/6"
                          data-testid="file-name"
                        >
                          {file.fileName}
                        </p>
                        <p
                          className="text-gray-500 truncate w-5/6"
                          data-testid="file-player-name"
                        >
                          {file.playerName}
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex w-1/3 justify-end"
                      data-testid="file-status-container"
                    >
                      <div className="" data-testid="status-icon">
                        {(file.uploadStatus === "PENDING" && (
                          <div
                            className="flex place-items-center"
                            data-testid="upload-processing"
                          >
                            <button
                              className="text-gray-500 mr-4 text-xs"
                              data-testid="popup-cancel-button"
                              id={`${file.fileId}`}
                              onClick={(event) =>
                                cancelFile(event, file.uploadSessionId)
                              }
                            >
                              cancel
                            </button>
                            <div
                              className="w-9"
                              data-testid={`${
                                isMobile
                                  ? "mobile-progress-container"
                                  : "progress-container"
                              }`}
                            >
                              <GradientProgressSpinner
                                progress={file.uploadProgress}
                              />
                            </div>
                          </div>
                        )) ||
                          (file.uploadStatus === "ERROR" && (
                            <div
                              className="flex place-items-center"
                              data-testid="upload-failed"
                            >
                              <button
                                className="text-gray-500 mr-4 text-xs"
                                data-testid="retry-button"
                                onClick={() =>
                                  retryFileUpload(
                                    file.fileName,
                                    file.uploadSessionId,
                                  )
                                }
                              >
                                Retry
                              </button>
                              <XIcon
                                className="w-6 mr-1"
                                data-testid="red-x-circle"
                              />
                            </div>
                          )) ||
                          (file.uploadStatus === "CANCELED" && (
                            <p
                              className="text-gray-500 text-xs"
                              data-testid="upload-canceled"
                            >
                              Upload canceled
                            </p>
                          )) ||
                          (file.uploadStatus === "COMPLETE" && (
                            <StepCircleComplete
                              className="w-6 mr-1"
                              data-testid="green-check-circle"
                              id={`green-check-circle-${index}`}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                );
              });
            })}
          </div>
          {isMobile && (
            <footer
              id="footer-container"
              className="flex justify-end pt-5 border-t border-gray-200 h-20 p-4"
              data-testid="mobile-popup-footer"
            >
              <PrimaryButton
                onClick={closePopup}
                data-testid="continue-button"
                className="ml-3"
                disabled={uploadInProgress}
              >
                Continue
              </PrimaryButton>
            </footer>
          )}
        </div>
      )}
    </ReactModal>
  );
};
