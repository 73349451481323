import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  accessKeyId: "",
  secretAccessKey: "",
  sessionToken: "",
  expiration: "",
  assumedRoleId: "",
  arn: "",
  credentialsString: "",
};

export const awsCredentialsSlice = createSlice({
  name: "awsCredentials",
  initialState,
  reducers: {
    setAwsCredentials: (state, action) => {
      state.accessKeyId = action.payload.accessKeyId;
      state.secretAccessKey = action.payload.secretAccessKey;
      state.sessionToken = action.payload.sessionToken;
      state.expiration = action.payload.expiration;
      state.assumedRoleId = action.payload.assumedRoleId;
      state.arn = action.payload.arn;
      state.credentialsString = action.payload.credentialsString;
    },
  },
});
