import React from "react";
import toast from "react-hot-toast";

import { ErrorToast } from "../../../components";
import { uploadSessionFormSlice } from "../../../redux/slices/uploadSessionFormSlice";
import { uploadSessionSlice } from "../../../redux/slices/uploadSessionSlice";
import {
  AbortControllerInfo,
  DialogStateType,
  UploadFile,
  UploadFileStatusEnum,
  UploadSessionFormValues,
} from "../../../types";
import { PostSessionUploadResponse } from "../../../types/apiTypes";
import { fileChunkSize, uploadFileToS3Bucket } from "./S3UploadFunctions";

type SubmitDataUploadProps = {
  dispatch: any;
  uploadSessionFormValues: UploadSessionFormValues;
  postSessionUpload: any;
  patchSessionUpload: any;
  setPopupTitle: React.Dispatch<React.SetStateAction<string>>;
  setPopupState: React.Dispatch<React.SetStateAction<boolean>>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFiles: UploadFile[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setAbortControllers: React.Dispatch<
    React.SetStateAction<AbortControllerInfo[]>
  >;
  setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogState: React.Dispatch<React.SetStateAction<DialogStateType>>;
};

const { storeUploadSessionState } = uploadSessionSlice.actions;
const { clearUploadSessionFormState } = uploadSessionFormSlice.actions;

const errorToast = (message: string) =>
  toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
    id: "uploadError",
    duration: 4000,
  });

const determineNumberOfChunks = (fileSize: number) => {
  let numberOfChunks = 0;
  if (fileSize % fileChunkSize > 0) {
    numberOfChunks += 1;
  }
  numberOfChunks += Math.floor(fileSize / fileChunkSize);
  return numberOfChunks;
};

export const submitDataUpload = ({
  dispatch,
  uploadSessionFormValues,
  postSessionUpload,
  patchSessionUpload,
  setPopupTitle,
  setPopupState,
  setModalState,
  uploadFiles,
  setCurrentStep,
  setAbortControllers,
  setUploadInProgress,
  setDialogState,
}: SubmitDataUploadProps) => {
  const frameRate = uploadSessionFormValues.frameRate;

  const newUploadSessionFormValues = {
    ...uploadSessionFormValues,
    files: uploadSessionFormValues.files.map(
      (file: { fileSizeBytes: number }) => {
        return {
          ...file,
          frameRate: parseInt(frameRate || "240"),
          numberOfChunks: determineNumberOfChunks(file.fileSizeBytes),
        };
      },
    ),
  };

  const openPopup = (success: boolean) => {
    setModalState(false);
    setCurrentStep(1);
    if (success && uploadSessionFormValues.files.length) {
      const numberOfFiles = uploadSessionFormValues.files.length;
      const filesSingularOrPlural = numberOfFiles === 1 ? "file" : "files";
      setPopupState(true);
      setPopupTitle(`Uploading ${numberOfFiles} ${filesSingularOrPlural}`);
      sessionStorage.setItem("popupState", "true");
      sessionStorage.setItem(
        "popupTitle",
        `Uploading ${numberOfFiles} ${filesSingularOrPlural}`,
      );
    }
  };

  if (!frameRate || frameRate === "") {
    setDialogState({
      shouldOpen: true,
      type: "frameRate",
    });
  } else {
    postSessionUpload(newUploadSessionFormValues)
      .unwrap()
      .then((response: PostSessionUploadResponse) => {
        openPopup(true);
        setUploadInProgress(true);
        return dispatch(
          storeUploadSessionState({
            sessionDate: newUploadSessionFormValues.sessionDate,
            playerId: newUploadSessionFormValues.selectedPlayer.value,
            orgId: newUploadSessionFormValues.orgId,
            uploadSessionId: response.uploadSessionId,
            preSignedUrls: response.preSignedUrls,
            finished: false,
            uploadedFiles: response.preSignedUrls.map((preSignedUrl: any) => {
              return {
                fileId: preSignedUrl.fileId,
                fileName: preSignedUrl.fileName,
                sessionFileId: preSignedUrl.sessionFileId,
                uploadSessionId: response.uploadSessionId,
                uploadStatus: UploadFileStatusEnum.Pending,
                playerName: newUploadSessionFormValues.selectedPlayer.label,
                info: preSignedUrl.info,
                uploadProgress: 0,
              };
            }),
          }),
        );
      })
      .then(
        (response: {
          payload: { preSignedUrls: any[]; uploadSessionId: number };
        }) => {
          if (response) {
            response.payload.preSignedUrls.forEach((preSignedUrlInfo: any) => {
              const controller: AbortController = new AbortController();
              const abortControllerInfo = {
                abortController: controller,
                fileName: preSignedUrlInfo.fileName,
              };
              setAbortControllers((prev: any) => [
                ...prev,
                abortControllerInfo,
              ]);
              uploadFileToS3Bucket(
                preSignedUrlInfo,
                response.payload.uploadSessionId,
                uploadFiles,
                dispatch,
                patchSessionUpload,
                abortControllerInfo,
              );
            });
          }
        },
      )
      .then(() => {
        dispatch(clearUploadSessionFormState());
      })
      .catch(() => {
        openPopup(false);
        errorToast(
          "There was an issue uploading the session. Please try again.",
        );
        dispatch(clearUploadSessionFormState());
        setUploadInProgress(false);
      });
  }
};
