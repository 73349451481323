import { createSlice } from "@reduxjs/toolkit";

export type DataExport = {
  playerName: string;
  orgPlayerId: string;
  sessionList: {
    id: string;
    date: string;
  }[];
  movementTypes: { id: string; value: string }[];
  dataTypes: { id: string; value: string }[];
  dataFormat: string;
};

const initialState: DataExport = {
  playerName: "",
  orgPlayerId: "",
  sessionList: [],
  movementTypes: [],
  dataTypes: [],
  dataFormat: "",
};

export const dataExportSlice = createSlice({
  name: "dataExport",
  initialState,
  reducers: {
    storeDataExport: (state, action) => {
      state.playerName = action.payload.playerName;
      state.orgPlayerId = action.payload.orgPlayerId;
      state.sessionList = action.payload.sessionList;
      state.movementTypes = action.payload.movementTypes;
      state.dataTypes = action.payload.dataTypes;
      state.dataFormat = action.payload.dataFormat;
    },
    clearDataExport: (state) => {
      state = initialState;
      return state;
    },
  },
});
