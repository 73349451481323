import { differenceInYears } from "date-fns";
import React, { useEffect, useState } from "react";

import { Player } from "../../types";

type PlayerGroupStatsProps = {
  selectedPlayers: Player[];
};

export const PlayerGroupStats: React.FC<PlayerGroupStatsProps> = ({
  selectedPlayers,
}: PlayerGroupStatsProps): JSX.Element => {
  const [rightHandedStat, setRightHandedStat] = useState<string>("");
  const [leftHandedStat, setLeftHandedStat] = useState<string>("");
  const [avgHeightFeetStat, setAvgHeightFeetStat] = useState<string>("");
  const [avgHeightInchesStat, setAvgHeightInchesStat] = useState<string>("");
  const [avgWeightStat, setAvgWeightStat] = useState<string>("");
  const [avgAgeStat, setAvgAgeStat] = useState<string>("");

  const determineHandStat = () => {
    const rightHandedPlayers = selectedPlayers.filter(
      (player: Player) => player.hits === "Righty",
    );
    const leftHandedPlayers = selectedPlayers.filter(
      (player: Player) => player.hits === "Lefty",
    );
    setRightHandedStat(rightHandedPlayers.length.toString());
    setLeftHandedStat(leftHandedPlayers.length.toString());
  };

  const determineWeightStat = () => {
    const combinedWeight = selectedPlayers.reduce(
      (acc: number, player: Player) => acc + Number(player.weight),
      0,
    );
    const avgWeight = Math.round(
      combinedWeight / selectedPlayers.length,
    ).toString();
    setAvgWeightStat(avgWeight);
  };

  const determineHeightStat = () => {
    const combinedHeight = selectedPlayers.reduce(
      (acc: number, player: any) => {
        if (player.heightFeet === "") {
          return acc;
        } else {
          const playerHeight = player.heightFeet;
          return acc + Number(playerHeight);
        }
      },
      0,
    );
    const avgHeight = combinedHeight / selectedPlayers.length;
    const heightFeet = Math.floor(avgHeight).toString();
    const heightInches = Math.round(
      (avgHeight - Math.floor(avgHeight)) * 12,
    ).toString();
    if (heightInches === "12") {
      setAvgHeightFeetStat((Number(heightFeet) + 1).toString());
      setAvgHeightInchesStat("0");
    } else {
      setAvgHeightFeetStat(heightFeet);
      setAvgHeightInchesStat(heightInches);
    }
  };

  const determineAgeStat = () => {
    const combinedAge = selectedPlayers.reduce(
      (acc: number, player: Player) => {
        const age = differenceInYears(new Date(), new Date(player.dateOfBirth));
        return acc + Number(age);
      },
      0,
    );
    const avgAge = Math.round(combinedAge / selectedPlayers.length).toString();
    setAvgAgeStat(avgAge);
  };

  useEffect(() => {
    if (selectedPlayers.length > 0) {
      determineHandStat();
      determineWeightStat();
      determineHeightStat();
      determineAgeStat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayers]);

  return (
    <div className="flex flex-col" data-testid="player-group-stats-container">
      <h3 className="text-[#4A4A4A] pb-6 border-b border-gray-100">
        Group Stats
      </h3>
      <div className="mt-6" data-testid="player-group-stats">
        <p className="text-[#979797]">Right Handed</p>
        <p
          className="text-primary-800 text-2xl mb-8 mt-2"
          data-testid="right-handed-stat"
        >
          {selectedPlayers.length > 0 ? rightHandedStat : "- -"}
        </p>
        <p className="text-[#979797]">Left Handed</p>
        <p
          className="text-primary-800 text-2xl mb-8 mt-2"
          data-testid="left-handed-stat"
        >
          {selectedPlayers.length > 0 ? leftHandedStat : "- -"}
        </p>
        <p className="text-[#979797]">Avg. Height</p>
        <p
          className="text-primary-800 text-2xl mb-8 mt-2"
          data-testid="height-stat"
        >
          {selectedPlayers.length > 0
            ? `${avgHeightFeetStat} ft ${avgHeightInchesStat} in`
            : "- -"}
        </p>
        <p className="text-[#979797]">Avg. Weight</p>
        <p
          className="text-primary-800 text-2xl mb-8 mt-2"
          data-testid="weight-stat"
        >
          {selectedPlayers.length > 0 ? `${avgWeightStat} lbs` : "- -"}
        </p>
        <p className="text-[#979797]">Avg. Age</p>
        <p
          className="text-primary-800 text-2xl mb-8 mt-2"
          data-testid="age-stat"
        >
          {selectedPlayers.length > 0 ? avgAgeStat : "- -"}
        </p>
      </div>
    </div>
  );
};
