import * as Sentry from "@sentry/react";

import {
  camelCaseResponse,
  determineNumberOfMovements,
  displayMovementCount,
  providesList,
} from "../shared/Functions";
import {
  ApiUploadData,
  GenericApiResponse,
  Session,
  SessionType,
  UploadSessionFormValues,
} from "../types";
import {
  FilePartInfo,
  PatchSessionUploadInfo,
  PostSessionUploadResponse,
} from "../types/apiTypes";
import { emptyApi } from "./emptyApi";

export const sessionExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSessionById: builder.query<Session, string>({
      query: (id) => `/session/${id}`,
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (result, error, id) => [{ type: "Sessions", id }],
    }),
    getSessionByOrgSessionId: builder.query<Session[], string>({
      query: (orgSessionId) => `/sessions?org_session_id=${orgSessionId}`,
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getSessionsByOrgId: builder.query<Session[], string>({
      query: (orgId) => `/sessions?org_id=${orgId}`,
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getSessionsByPlayerId: builder.query<
      Session[],
      { orgPlayerId: string; limit: number; offset: number }
    >({
      query: (args) => {
        const { orgPlayerId, limit, offset } = args;
        return {
          url: "/sessions",
          params: {
            org_player_ids: orgPlayerId,
            limit,
            offset,
          },
        };
      },
      transformResponse: (response: any) => {
        return processRetrievedSessions(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getAllSessions: builder.query<Session[], void>({
      query: () => "/sessions?offset=0&limit=1000",
      transformResponse: (response: any) => {
        return processRetrievedSessions(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getSessionsByStatus: builder.query<
      Session[],
      { status: string; limit?: number; offset: number }
    >({
      query: (args) => {
        const { status, limit, offset } = args;
        return {
          url: `/sessions`,
          params: { status, limit, offset },
        };
      },
      transformResponse: (response: any) => {
        return processRetrievedSessions(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getAllSessionsOfType: builder.query<Session[], string>({
      query: (sessionTypeId) =>
        `/sessions?session_id=${sessionTypeId}&offset=0&limit=100`,
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getSessionTypes: builder.query<SessionType[], void>({
      query: () => "/session_types",
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    postSessionUpload: builder.mutation<
      PostSessionUploadResponse,
      UploadSessionFormValues
    >({
      query: (uploadSessionFormValues) => ({
        url: "/upload_videos",
        method: "POST",
        body: processUploadSessionFormValues(uploadSessionFormValues),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    patchSessionUpload: builder.mutation<
      GenericApiResponse,
      PatchSessionUploadInfo
    >({
      query: (args) => ({
        url: "/upload_videos",
        method: "PATCH",
        body: processFinalUpload(
          args["fileId"],
          args["uploadSessionId"],
          args["uploadSuccessful"],
          args["filePartInfo"],
        ),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["Sessions", "Org"],
    }),
  }),
});

export const {
  useGetSessionByIdQuery,
  useGetSessionsByOrgIdQuery,
  useGetSessionsByPlayerIdQuery,
  useGetSessionByOrgSessionIdQuery,
  useGetAllSessionsQuery,
  useGetSessionsByStatusQuery,
  useGetAllSessionsOfTypeQuery,
  useGetSessionTypesQuery,
  usePostSessionUploadMutation,
  usePatchSessionUploadMutation,
} = sessionExtendedApi;

function processUploadSessionFormValues(
  uploadSessionFormValues: UploadSessionFormValues,
): Record<string, unknown> {
  const {
    orgId,
    selectedPlayer,
    sessionDate,
    sessionType,
    movementType,
    movementTags,
    files,
    frameRate,
  } = uploadSessionFormValues;
  const uploadData: ApiUploadData = {
    org_id: orgId,
    session_type_id: parseInt(sessionType.value),
    movement_type_id: parseInt(movementType.value),
    player_id: selectedPlayer.value,
    session_date: sessionDate,
    files: [{}],
    movement_tag_ids: [],
  };
  uploadData.files = files.map((file) => {
    return {
      file_id: file.fileId,
      raw_file_name: file.rawFileName,
      file_size_bytes: file.fileSizeBytes,
      frame_rate: parseInt(frameRate),
      number_of_chunks: file.numberOfChunks,
    };
  });
  movementTags.forEach((movementTag) => {
    uploadData.movement_tag_ids.push(parseInt(movementTag.value));
  });
  return uploadData;
}

function processFinalUpload(
  fileId: number,
  uploadSessionId: number,
  uploadSuccessful: boolean,
  filePartInfo?: FilePartInfo,
): Record<string, unknown> {
  return {
    upload_session_id: uploadSessionId,
    affected_file_id: fileId,
    file_part_info: filePartInfo ? filePartInfo : [],
    upload_successful: uploadSuccessful,
  };
}

function processRetrievedSessions(camelCasedResponse: any): Session[] {
  let sessions: Session[] = [];
  try {
    sessions = camelCasedResponse.map((session: any) => {
      return {
        id: session.id,
        sessionType: {
          id: session.sessionType.id,
          slug: session.sessionType.slug.includes("-")
            ? session.sessionType.slug.replace(/-/g, " ")
            : session.sessionType.slug,
          name: session.sessionType.name,
        },
        mocapType: {
          id: session.mocapType.id,
          slug: session.mocapType.slug,
        },
        numberOfMovements: determineNumberOfMovements(session.totalMovements),
        status:
          session.failedMovements > 20
            ? "in-error"
            : session.percentComplete > 99
              ? "completed"
              : "in-progress",
        officialGameId: session.officialGameId ?? 0,
        sessionStart: session.sessionStart ?? "unknown",
        sessionComplete: session.session_complete ?? "unknown",
        sessionDate: new Date(`${session.sessionDate}T00:00:00`).toISOString(),
        sessionVenue: session.sessionVenue ?? "default",
        uploadDate: session.createdAt,
        percentComplete: Math.round(session.percentComplete),
        failedMovements:
          session.failedMovements > 20 ? session.failedMovements : 0,
        sessionTableValues: {
          numberOfMovements: displayMovementCount(session.totalMovements),
        },
        sessionId: session.officialGameId ? session.officialGameId : session.id,
        updatedAt: session.updatedAt,
      };
    });
  } catch (err) {
    console.warn(
      "There was an issue with processing the returned values: ",
      camelCasedResponse,
    );
    console.warn("The error was: ", err);
    Sentry.captureException(err);
  }

  return sessions;
}
