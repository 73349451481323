import { camelCaseResponse } from "../shared/Functions";
import { emptyApi } from "./emptyApi";

export type AwsCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: string;
  assumedRoleId: string;
  arn: string;
  credentialsString: string;
};

export const awsCredentialsExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAwsCredentials: builder.query<AwsCredentials, void>({
      query: () => "/aws_credentials",
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const { useGetAwsCredentialsQuery } = awsCredentialsExtendedApi;
