import { TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import {
  ComparisonDropdown,
  CriteriaDropdown,
  MocapTypeDropdown,
  PitchCharacteristicInches,
  PitchCharacteristicMPH,
  PitchTypeDropdown,
  PlayerDropdown,
  PlayerGroupDropdown,
} from "../..";
import { CustomDropdownWithChevron } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { orgSlice } from "../../../redux/slices/orgSlice";
import { playerSlice } from "../../../redux/slices/playerSlice";
import { requestedAnalysisFormSlice } from "../../../redux/slices/requestedAnalysisFormSlice";
import {
  useGetAllPlayersQuery,
  useGetOrgInfoQuery,
  useGetPitchTypesQuery,
  useGetPlayerGroupByIdQuery,
  useGetPlayerGroupsQuery,
} from "../../../services";
import { customSelectStyles, playerListLimit } from "../../../shared/Constants";
import { joinClassNames } from "../../../shared/Functions";
import {
  Player,
  PlayerGroupApiData,
  PlayerGroupSegmentCriteria,
  SegmentDetails,
  SelectField,
} from "../../../types";

type SegmentCriteriaProps = {
  index: number;
  movementTypeId: number;
  segmentType: string;
  selectFieldOptions: SelectField[];
  setSelectFieldOptions: React.Dispatch<React.SetStateAction<SelectField[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  currentSegmentState: SegmentDetails;
  setSegmentCriteria: React.Dispatch<
    React.SetStateAction<PlayerGroupSegmentCriteria[]>
  >;
  segmentKey: string;
  fieldsSelected: string[];
  setFieldsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  isLoading?: boolean;
};

export const SegmentCriteria: React.FC<SegmentCriteriaProps> = ({
  index,
  movementTypeId,
  segmentType,
  selectFieldOptions,
  setSelectFieldOptions,
  setFieldValue,
  currentSegmentState,
  setSegmentCriteria,
  segmentKey,
  fieldsSelected,
  setFieldsSelected,
  setFieldTouched,
  isLoading,
}: SegmentCriteriaProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [offsetForPlayerList, setOffsetForPlayerList] = useState(0);
  const [combinedPlayers, setCombinedPlayers] = useState<Player[]>([]);
  const [playerQueryCount, setPlayerQueryCount] = useState(0);

  const [playerGroupId, setPlayerGroupId] = useState<number>(0);

  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );
  const storedPlayerList = useAppSelector((state) => state.players.playerList);
  const playerCount = useAppSelector((state) => state.org).players.total;
  const mocapTypes = useAppSelector((state) => state.org.details.mocapTypes);

  const numberOfQueriesForPlayers = Math.ceil(playerCount / playerListLimit);

  const playersSortedByFirstName = [...storedPlayerList].sort((a, b) =>
    a.firstName < b.firstName ? -1 : 1,
  );

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeRequestedAnalysisFormState, updateSegmentCriteria } =
    requestedAnalysisFormSlice.actions;
  const { storePlayers } = playerSlice.actions;
  const { setOrgInformation } = orgSlice.actions;

  // RTK Queries
  const { data: pitchTypes } = useGetPitchTypesQuery(movementTypeId, {
    skip: movementTypeId === 0,
  });
  const { data: players, originalArgs } = useGetAllPlayersQuery(
    {
      offset: offsetForPlayerList,
      limit: playerListLimit,
    },
    {
      skip:
        storedPlayerList.length === playerCount ||
        playerQueryCount === numberOfQueriesForPlayers,
    },
  );
  const { data: org } = useGetOrgInfoQuery(undefined, {
    skip: mocapTypes.length > 0,
  });
  const { data: playerGroupData } = useGetPlayerGroupsQuery();
  const { data: playersInGroup } = useGetPlayerGroupByIdQuery(
    playerGroupId ? playerGroupId : undefined,
    {
      skip: !playerGroupId,
    },
  );

  useEffect(() => {
    if (playersInGroup) {
      const groupPlayerIds = playersInGroup.map(
        (player: Player) => player.orgPlayerId,
      );
      const groupName = playerGroupData.find(
        (group: PlayerGroupApiData) => group.groupId === playerGroupId,
      )?.name;
      dispatch(
        updateSegmentCriteria({
          segmentType,
          index,
          key: "option",
          value: {
            value: groupName,
            label: groupName,
            id: groupPlayerIds,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playersInGroup]);

  useEffect(() => {
    if (org) {
      dispatch(setOrgInformation(org));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  useEffect(() => {
    if (players && originalArgs) {
      if (players.length === playerListLimit) {
        setOffsetForPlayerList(originalArgs.offset + playerListLimit);
        setCombinedPlayers(combinedPlayers.concat(players));
        setPlayerQueryCount(playerQueryCount + 1);
      } else if (players.length < playerListLimit) {
        setCombinedPlayers(combinedPlayers.concat(players));
        setOffsetForPlayerList(0);
        setPlayerQueryCount(playerQueryCount + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);

  useEffect(() => {
    if (storedPlayerList.length !== playerCount && combinedPlayers) {
      dispatch(storePlayers(combinedPlayers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedPlayers]);

  const playerOptions = playersSortedByFirstName.map((player) => {
    return {
      value: player.orgPlayerId,
      label: `${player.firstName} ${player.lastName}`,
      id: player.orgPlayerId,
      dob: player.dateOfBirth,
    };
  });

  const pitchTypeOptions =
    pitchTypes?.pitchTypes
      .map((type) => {
        return {
          value: type,
          label: type,
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1)) ?? [];

  const mocapPitchTypeOptions =
    pitchTypes?.mocapPitchTypes
      .map((type) => {
        return {
          value: type,
          label: type,
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1)) ?? [];

  const mocapTypeOptions =
    mocapTypes
      .map((mocapType) => {
        return {
          value: mocapType.slug,
          label: mocapType.name,
          id: mocapType.id,
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1)) ?? [];

  const playerGroupOptions = playerGroupData
    ?.map((playerGroup: any) => {
      return {
        value: playerGroup.name,
        label: playerGroup.name,
        id: playerGroup.groupId,
      };
    })
    .sort((a: { label: number }, b: { label: number }) =>
      a.label < b.label ? -1 : 1,
    );

  const deleteCriteria = (i: number) => {
    const newCriteria = [...currentSegmentState.criteria];
    newCriteria.splice(i, 1);
    setSegmentCriteria(newCriteria);
    dispatch(
      storeRequestedAnalysisFormState({
        ...requestedAnalysisFormState,
        [segmentKey]: {
          ...currentSegmentState,
          criteria: newCriteria,
        },
      }),
    );
    setFieldValue(`${segmentType}Segment.criteria`, newCriteria);
  };

  useEffect(() => {
    setFieldsSelected(
      currentSegmentState.criteria.map((criterion) =>
        criterion ? criterion.field.value : "",
      ),
    );
  }, [fieldsSelected.length, currentSegmentState, setFieldsSelected]);

  useEffect(() => {
    if (
      pitchTypes &&
      pitchTypeOptions.length &&
      !selectFieldOptions.find((option) => option.value === "pitchType") &&
      !fieldsSelected.includes("pitchType")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "pitchType",
          label: "Pitch Type",
        },
      ]);
    }
    if (
      pitchTypes &&
      mocapPitchTypeOptions.length &&
      !fieldsSelected.includes("mocapPitchType") &&
      !selectFieldOptions.find((option) => option.value === "mocapPitchType")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "mocapPitchType",
          label: "Mocap Pitch Type",
        },
      ]);
    }
    if (
      mocapTypes &&
      mocapTypeOptions.length &&
      !selectFieldOptions.find((option) => option.value === "mocapType") &&
      !fieldsSelected.includes("mocapType")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "mocapType",
          label: "Mocap Type",
        },
      ]);
    }
    if (
      storedPlayerList.length &&
      playerOptions.length &&
      !selectFieldOptions.find((option) => option.value === "player") &&
      !fieldsSelected.includes("player")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "player",
          label: "Player",
        },
      ]);
    }
    if (
      playerGroupData &&
      playerGroupOptions.length &&
      !selectFieldOptions.find((option) => option.value === "playerGroup") &&
      !fieldsSelected.includes("playerGroup")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "playerGroup",
          label: "Player Group",
        },
      ]);
    }
    if (
      !selectFieldOptions.find(
        (option) => option.value === "horizontalBreak",
      ) &&
      !fieldsSelected.includes("horizontalBreak")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "horizontalBreak",
          label: "Horizontal Break",
        },
      ]);
    }
    if (
      !selectFieldOptions.find((option) => option.value === "verticalBreak") &&
      !fieldsSelected.includes("verticalBreak")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "verticalBreak",
          label: "Vertical Break",
        },
      ]);
    }
    if (
      !selectFieldOptions.find(
        (option) => option.value === "inducedVerticalBreak",
      ) &&
      !fieldsSelected.includes("inducedVerticalBreak")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "inducedVerticalBreak",
          label: "Induced Vertical Break",
        },
      ]);
    }
    if (
      !selectFieldOptions.find((option) => option.value === "releaseSpeed") &&
      !fieldsSelected.includes("releaseSpeed")
    ) {
      setSelectFieldOptions([
        ...selectFieldOptions,
        {
          value: "releaseSpeed",
          label: "Release Speed",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsSelected,
    mocapTypeOptions,
    pitchTypeOptions,
    mocapPitchTypeOptions,
    playerOptions,
    playerGroupOptions,
  ]);

  useEffect(() => {
    if (
      fieldsSelected.includes("pitchType") &&
      selectFieldOptions.find((option) => option.value === "pitchType")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter((option) => option.value !== "pitchType"),
      );
    }
    if (
      fieldsSelected.includes("mocapPitchType") &&
      selectFieldOptions.find((option) => option.value === "mocapPitchType")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter(
          (option) => option.value !== "mocapPitchType",
        ),
      );
    }
    if (
      fieldsSelected.includes("player") &&
      selectFieldOptions.find((option) => option.value === "player")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter((option) => option.value !== "player"),
      );
    }
    if (
      fieldsSelected.includes("mocapType") &&
      selectFieldOptions.find((option) => option.value === "mocapType")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter((option) => option.value !== "mocapType"),
      );
    }
    if (
      fieldsSelected.includes("playerGroup") &&
      selectFieldOptions.find((option) => option.value === "playerGroup")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter((option) => option.value !== "playerGroup"),
      );
    }
    if (
      fieldsSelected.filter((field) => field === "horizontalBreak").length ===
        2 &&
      selectFieldOptions.find((option) => option.value === "horizontalBreak")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter(
          (option) => option.value !== "horizontalBreak",
        ),
      );
    }
    if (
      fieldsSelected.filter((field) => field === "verticalBreak").length ===
        2 &&
      selectFieldOptions.find((option) => option.value === "verticalBreak")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter((option) => option.value !== "verticalBreak"),
      );
    }
    if (
      fieldsSelected.filter((field) => field === "inducedVerticalBreak")
        .length === 2 &&
      selectFieldOptions.find(
        (option) => option.value === "inducedVerticalBreak",
      )
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter(
          (option) => option.value !== "inducedVerticalBreak",
        ),
      );
    }
    if (
      fieldsSelected.filter((field) => field === "releaseSpeed").length === 2 &&
      selectFieldOptions.find((option) => option.value === "releaseSpeed")
    ) {
      setSelectFieldOptions(
        selectFieldOptions.filter((option) => option.value !== "releaseSpeed"),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsSelected]);

  return (
    <div
      className={joinClassNames(
        isLoading ? "opacity-50 pointer-events-none" : "",
        "flex",
      )}
      data-testid={`${segmentType}-segment-criteria`}
    >
      <CriteriaDropdown
        segmentType={segmentType}
        currentSegmentState={currentSegmentState}
        index={index}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        dispatch={dispatch}
        updateSegmentCriteria={updateSegmentCriteria}
        selectFieldOptions={selectFieldOptions}
      />

      <ComparisonDropdown
        segmentType={segmentType}
        currentSegmentState={currentSegmentState}
        index={index}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        dispatch={dispatch}
        updateSegmentCriteria={updateSegmentCriteria}
      />

      {currentSegmentState.criteria[index] &&
      currentSegmentState.criteria[index].field.value === "player" ? (
        <PlayerDropdown
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
          playerOptions={playerOptions}
          inputValue={inputValue}
          setInputValue={setInputValue}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
        />
      ) : currentSegmentState.criteria[index] &&
        currentSegmentState.criteria[index].field.value === "pitchType" ? (
        <PitchTypeDropdown
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
          pitchTypeOptions={pitchTypeOptions}
        />
      ) : currentSegmentState.criteria[index] &&
        currentSegmentState.criteria[index].field.value === "mocapPitchType" ? (
        <PitchTypeDropdown
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
          pitchTypeOptions={mocapPitchTypeOptions}
          mocap
        />
      ) : currentSegmentState.criteria[index] &&
        currentSegmentState.criteria[index].field.value === "mocapType" ? (
        <MocapTypeDropdown
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
          mocapTypeOptions={mocapTypeOptions}
        />
      ) : currentSegmentState.criteria[index] &&
        currentSegmentState.criteria[index].field.value === "playerGroup" ? (
        <PlayerGroupDropdown
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
          playerGroupOptions={playerGroupOptions}
          setPlayerGroupId={setPlayerGroupId}
        />
      ) : currentSegmentState.criteria[index] &&
        (currentSegmentState.criteria[index].field.value ===
          "horizontalBreak" ||
          currentSegmentState.criteria[index].field.value === "verticalBreak" ||
          currentSegmentState.criteria[index].field.value ===
            "inducedVerticalBreak") ? (
        <PitchCharacteristicInches
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
        />
      ) : currentSegmentState.criteria[index] &&
        currentSegmentState.criteria[index].field.value === "releaseSpeed" ? (
        <PitchCharacteristicMPH
          segmentType={segmentType}
          currentSegmentState={currentSegmentState}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          dispatch={dispatch}
          updateSegmentCriteria={updateSegmentCriteria}
        />
      ) : (
        <div
          className="w-60"
          data-testid={`${segmentType}-empty-dropdown-container`}
        >
          <Select
            name={`${segmentType}-empty-dropdown-${index}`}
            aria-labelledby={`${segmentType}-empty-dropdown-aria-label`}
            inputId={`${segmentType}-empty-dropdown-${index}`}
            isSearchable={false}
            options={[]}
            placeholder="Select..."
            styles={customSelectStyles}
            components={{
              DropdownIndicator: CustomDropdownWithChevron,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      <button
        className="w-6 ml-4"
        data-testid={`${segmentType}-trash-button-${index}`}
        onClick={(event) => {
          event.preventDefault();
          deleteCriteria(index);
        }}
      >
        <TrashIcon />
      </button>
    </div>
  );
};
