import { User } from "@auth0/auth0-react";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";

import { ReactComponent as HamburgerIcon } from "../../assets/HamburgerIcon.svg";
import { useAppContext } from "../../context";
import { useAuth0 } from "../../shared/Auth0Utility";
import { joinClassNames } from "../../shared/Functions";

type DropdownMenuProps = {
  user: User;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  user,
}: DropdownMenuProps): JSX.Element => {
  const { loadingDashboard } = useAppContext();
  const { logout } = useAuth0();

  return (
    <Menu as="div" data-testid="dropdown-menu-container">
      <Menu.Button data-testid="dropdown-button">
        <>
          <ChevronDownIcon
            className="hidden lg:block text-gray-900 ml-2 w-5"
            data-testid="down-arrow-icon"
          />
          <HamburgerIcon
            className="block lg:hidden text-gray-900 ml-2 w-8 h-8"
            data-testid="hamburger-icon"
          />
        </>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <>
            <div
              className="py-1 lg:hidden border-b border-gray-100"
              data-testid="user-container"
            >
              <Menu.Item>
                {() => (
                  <p className="text-gray-700 flex flex-col px-4 py-2 text-sm">
                    <span className="py-1 font-normal">Signed in as </span>
                    <span className="font-bold">{user.name}</span>
                  </p>
                )}
              </Menu.Item>
            </div>
          </>
          <div
            className={joinClassNames(
              loadingDashboard ? "lg:hidden" : "lg:block",
              "py-1 hidden",
            )}
            data-testid="api-keys-container"
          >
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/develop"
                  className={joinClassNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm",
                  )}
                  data-testid="api-keys-link"
                >
                  API Keys
                </a>
              )}
            </Menu.Item>
          </div>
          <div
            className={joinClassNames(
              loadingDashboard ? "lg:hidden" : "lg:block",
              "py-1 hidden",
            )}
            data-testid="developer-resources-container"
          >
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/develop/resources"
                  className={joinClassNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm",
                  )}
                  data-testid="developer-resources-link"
                >
                  Developer Resources
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1" data-testid="help-container">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={joinClassNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "px-4 py-2 flex items-center text-sm",
                  )}
                  href="#"
                  onClick={() => window.Beacon("open")}
                  data-testid="helpscout-link"
                >
                  <QuestionMarkCircleIcon
                    className="lg:hidden flex-shrink-0 h-5 w-5 mr-2 text-gray-900"
                    data-testid="help-icon"
                  />
                  Help
                </a>
              )}
            </Menu.Item>
          </div>
          <div
            className="py-1 border-t border-gray-100"
            data-testid="sign-out-container"
          >
            <Menu.Item>
              {({ active }) => (
                <button
                  className={joinClassNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full px-4 py-2 flex items-center text-sm",
                  )}
                  id="sign-out-button"
                  data-testid="sign-out-button"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  <ArrowRightStartOnRectangleIcon
                    className="flex flex-shrink-0 h-5 w-5 mr-2 text-gray-900"
                    aria-hidden="true"
                    data-testid="sign-out-icon"
                  />
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
