import { camelCaseResponse, providesList } from "../shared/Functions";
import { emptyApi } from "./emptyApi";

export const apiKeyExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    createApiKey: builder.mutation({
      query: () => ({
        url: "/api_key",
        method: "POST",
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["ApiKeys"],
    }),
    getApiKeys: builder.query({
      query: () => "/api_keys",
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "ApiKeys"),
    }),
    deactivateApiKey: builder.mutation({
      query: (keyId: number) => ({
        url: `/api_key/${keyId}/deactivate`,
        method: "PATCH",
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["ApiKeys"],
    }),
    deleteApiKey: builder.mutation({
      query: (keyId: number) => ({
        url: `/api_key/${keyId}`,
        method: "DELETE",
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["ApiKeys"],
    }),
  }),
});

export const {
  useCreateApiKeyMutation,
  useGetApiKeysQuery,
  useDeactivateApiKeyMutation,
  useDeleteApiKeyMutation,
} = apiKeyExtendedApi;
