import React from "react";
import { Helmet } from "react-helmet";

import { useAppSelector } from "../../redux/hooks";

export const HelpScoutBeacon = (): JSX.Element => {
  const user = useAppSelector((state) => state.user);

  return (
    <Helmet>
      <script type="text/javascript">
        {`
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
        `}
      </script>
      <script type="text/javascript">
        {`
          window.Beacon('init', 'ef7a5f7e-bdd9-4e66-bff3-f2b696eb106e')
          window.Beacon('identify', {
            name: '${user.name}',
            email: '${user.email}',
          })
          window.Beacon('config', {
            'display': {
              position: 'left'
            }
          })
        `}
      </script>
    </Helmet>
  );
};
